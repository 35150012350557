import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Button from '../../../../components/Button';
import CardGlobal from '../../../../components/CardGlobal';
import MedicalAppointmentDetails from '../../../../components/MedicalAppointmentDetails';
import FileDownloaderButton from '../../../../components/FileDownloaderButton';

import {
  Container,
  MedicalAppointmentDetailsContainer,
  DocumentsContainer,
  ButtonsContainer,
  AcceptRejectButtonsContainer,
} from './styles';
import AgendamentoService from '../../../../services/AgendamentoService';
import { Agendamento } from '../../../../models/agendamento';
import useQuery from '../../../../utils/query';
import { Notificacao } from '../../../../models/notificacao';
import Loading from '../../../../components/Loading';
import * as signalR from '@aspnet/signalr';
import { useAuth } from '../../../../hooks/auth';
import { formatInputDateToApi } from '../../../../utils/formatDateToApi';

const Details: React.FC = () => {
  const { signOut } = useAuth();
  const query = useQuery();
  const history = useHistory();

  const [address, setAddress] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [isPhysicianInTheRoom, setIsPhysicianInTheRoom] = useState(false);
  const [agendamento, setAgendamento] = useState<Agendamento>(
    {} as Agendamento,
  );
  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_URL}Hub`)
    .build();

  const handleClickEnter = () => {
    history.push(`/area/consulta/${agendamento.id}`);
  };

  const handleUpdateSchedule = async () => {
    try {
      setUpdateSuccess(false);
      await AgendamentoService.update(agendamento.id, {
        ...agendamento,
        dia: formatInputDateToApi(agendamento.dia),
        cancelado: true,
      });
      setUpdateSuccess(true);
    } catch (e) {
      toast.error(
        `Não foi possível atualizar o agendamento. \n\n${
          (e as AxiosError).response?.data
        }\n\n${(e as Error).message}`,
      );
    }
  };

  const handleGetAgendamento = async () => {
    try {
      const id = query.get('id');

      if (id) {
        const data = await AgendamentoService.getById(id);

        setAgendamento({
          ...data,
          dia: format(new Date(data.dia as string), 'dd/MM/yyyy'),
        });
        setAddress(
          `${
            data.estabelecimento?.logradouro
              ? `${data.estabelecimento?.logradouro}, `
              : ' '
          }${data.estabelecimento?.numero ?? ''} ${
            data.estabelecimento?.complemento
              ? `${data.estabelecimento?.complemento} - `
              : ''
          }${
            data.estabelecimento?.bairro
              ? `${data.estabelecimento?.bairro}, `
              : ''
          } ${data.estabelecimento?.nomeFantasia}`,
        );
      } else {
        history.push('/area/agendamento');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    if (!updateSuccess) return;

    hubConnection
      .start()
      .then(() => {
        hubConnection.invoke('SendRefreshTable');

        hubConnection
          .invoke('SendWaitingForDoctor', `cancel:${agendamento.id}`)
          .then(() => {
            signOut();
          });
      })
      .catch(e => {
        //toast.error(`Erro de conexão - Problema no websocket\n\n${e.message}`);
      });
  }, [updateSuccess]);

  useEffect(() => {
    handleGetAgendamento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    hubConnection
      .start()
      .then(() => {
        hubConnection.on('ReceivedOpenRoom', data => {
          if (data?.includes(agendamento?.id) && data?.includes('video')) {
            setIsPhysicianInTheRoom(true);
            handleClickEnter();
          }
          if (data?.includes(agendamento?.id) && data?.includes('update')) {
            handleGetAgendamento();
          }
        });

        hubConnection?.on('ReceivedWaitingForDoctor', (msg: string) => {
          const [event, id] = msg.split(':');
          if (event === 'finish' && id === agendamento.id) {
            toast.warning(
              'O atendimento foi finalizado pelo médico. Você será redirecionado para tela inicial.',
            );
            signOut();
          }
        });

        hubConnection.invoke('SendWaitingForDoctor', `open:${agendamento.id}`);
        hubConnection.onclose(
          () => {}, //toast.error('Conexão lenta - Tente novamente mais tarde.'),
        );
      })
      .catch(e => {
        //toast.error(`Erro de conexão - Problema no websocket\n\n${e.message}`);
      });

    // Disconecta na desmotagem da tela
    return () => {
      hubConnection.invoke('SendWaitingForDoctor', `close:${agendamento.id}`);
      hubConnection.stop();
    };
  }, [agendamento]);

  useEffect(() => {
    if (agendamento.emAndamento) {
      history.push(`/area/consulta/${agendamento.id}`);
    }
  }, [agendamento]);

  return (
    <Container>
      <CardGlobal>
        <MedicalAppointmentDetailsContainer>
          <h1>{agendamento.tipoDaConsulta}</h1>
          <MedicalAppointmentDetails
            date={agendamento.dia}
            schedule={agendamento.hora}
            name={
              agendamento.profissional?.nome ||
              'Um profissional assumirá o atendimento.'
            }
            address={address}
            specialty={agendamento.procedimento?.descricao}
            comments={agendamento.observacoes ?? ''}
          />
        </MedicalAppointmentDetailsContainer>

        <ButtonsContainer>
          {isPhysicianInTheRoom ? (
            <Button textSize={15} btnType="primary" onClick={handleClickEnter}>
              Entrar na sala de atendimento
            </Button>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p style={{ marginRight: '20px' }}>
                Aguarde o médico entrar na sala...
              </p>
              <Loading size={20} />
            </div>
          )}
          <Button
            textSize={15}
            btnType="outlinePrimary"
            onClick={handleUpdateSchedule}
            //disabled={}
          >
            Cancelar atendimento
          </Button>
          {/* <AcceptRejectButtonsContainer>
            <Button textSize={15} btnType="primary">
              Aceitar
            </Button>
            <Button textSize={15} btnType="primary">
              Recusar
            </Button>
          </AcceptRejectButtonsContainer> */}
        </ButtonsContainer>
      </CardGlobal>
    </Container>
  );
};

export default Details;
