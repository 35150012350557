/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import {
  Control,
  FieldValue,
  FieldValues,
  useController,
  useForm,
} from 'react-hook-form';

import { Container, Content } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title?: string;
  type?: string;
  control?: Control<FieldValue<FieldValues>>;
}

const Input: React.FC<InputProps> = ({ name, title, control, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const form = useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name,
    control: control || form.control,
    defaultValue: '',
  });

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {title && <strong>{title}</strong>}

      <Content isErrored={!!error} isFocused={isFocused}>
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Input;
