import moment from 'moment';
import { useCallback, useState } from 'react';
import AgendamentoApi from '../../services/AgendamentoService';
import { useAuth } from '../auth';
import { AxiosError } from 'axios';
import AgendamentoService from '../../services/AgendamentoService';
import { Agendamento } from '../../models/agendamento';

interface UseShedulesReturn {
  getSchedules: (finalizado?: boolean | null, dia?: string) => Promise<void>;
  //   postSchedules: (agendamento: Agendamento) => Promise<void>;
  //   updateSchedules: (agendamento: Agendamento, cancelado?: boolean) => Promise<void>;
  postSchedule: (
    userId: string,
    stablishment: string,
    specialty: string,
  ) => void;
  getSchedulesById: (id: string) => void;
  scheduleItems: AgendamentoDTO[];
  loading: boolean;
  error: string;
  success: boolean;
  schedule?: Agendamento;
}

interface AgendamentoDTO {
  id?: string;
  data?: string;
  hora?: string;
  tipo?: string;
  profissional?: string;
  especialidade?: string;
}

export function useSchedules(): UseShedulesReturn {
  const { id } = useAuth();

  const [loading, setLoading] = useState(false);
  const [scheduleItems, setScheduleItems] = useState<AgendamentoDTO[]>([]);
  const [schedule, setSchedule] = useState<Agendamento>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const getSchedules = useCallback(
    async (finalizado, dia) => {
      try {
        if (!id) return;
        setLoading(true);
        setError('');
        const agendamentos = await AgendamentoApi.get({ id, dia, finalizado });
        if (!agendamentos) {
          setScheduleItems([]);
          return;
        }
        const apiAgendamentos = agendamentos.map((agendamento: any) => ({
          id: agendamento.id,
          data: moment(agendamento.dia).format('DD/MM/YYYY'),
          hora: agendamento.hora,
          tipo: agendamento.tipoDaConsulta,
          profissional: agendamento.profissional?.nome,
          especialidade: agendamento.procedimento?.descricao,
        }));
        setScheduleItems(apiAgendamentos);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError('Não foi possivel carregar os agendamentos');
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  const getSchedulesById = async (id: string) => {
    try {
      setLoading(true);
      setError('');
      const schedule = await AgendamentoApi.getById(id);
      setSchedule(schedule);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setError(
        `Não foi possível buscar o agendamento.\n\n${
          (e as AxiosError).response?.data
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const postSchedule = async (
    userId: string,
    stablishment: string,
    specialty: string,
  ) => {
    try {
      setLoading(true);
      setError('');
      setSuccess(false);
      const response = await AgendamentoService.create({
        individuoId: userId,
        estabelecimentoId: stablishment,
        procedimentoId: specialty,
        cancelado: false,
        profissionalId: null,
        presencaConfirmada: true,
        retorno: false,
        tipoDaConsulta: 'Totem',
      });
      localStorage.setItem('scheduleId', response);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setError(
        `Não foi possivel criar o agendamentos. \n\n${
          (e as AxiosError).response?.data
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  //   const postSchedules = useCallback(
  //     async (agendamento: Agendamento) => {
  //       try {
  //         setLoading(true);
  //         setError('');
  //         await AgendamentoApi.post({ ...agendamento, individuoId: user?.id, cancelado: false });
  //         setSuccess('Dados enviados com sucesso');
  //       } catch (err) {
  //         console.log('error', err);
  //         setSuccess('');
  //         setError('Não foi possivel enviar os dados');
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     [user],
  //   );
  //   const updateSchedules = useCallback(
  //     async (agendamento: IAgendamento, cancelado?: boolean) => {
  //       try {
  //         setLoading(true);
  //         setError('');
  //         await AgendamentoApi.update(agendamento.id, {
  //           ...agendamento,
  //           individuoId: user?.id,
  //         });

  //         setSuccess('Dados enviados com sucesso');
  //       } catch (err) {
  //         setSuccess('');
  //         setError('Não foi possivel enviar os dados');
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     [user],
  //   );

  return {
    getSchedules,
    // postSchedules,
    // updateSchedules,
    postSchedule,
    getSchedulesById,
    schedule,
    scheduleItems,
    loading,
    error,
    success,
  };
}
