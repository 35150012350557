import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import LocalStorageKeyEnum from '../enums/LocalStorageKeyEnum';
import MainTemplate from '../templates/MainTemplate';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isIndependent?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isIndependent = false,
  component: Component,
  ...rest
}) => {
  const { id } = useAuth();
  const token = id
    ? localStorage.getItem(LocalStorageKeyEnum.AccessToken)
    : null;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!token ? (
          <MainTemplate
            withHeader={!isIndependent}
            withLateralMenu={!isIndependent && isPrivate}
            route={rest.path?.toString() ?? ''}
          >
            <Component />
          </MainTemplate>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/area',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
