import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  height: 90%;
  width: 60vw;
  max-height: 75vh;
  background-color: #eee;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.graySecondaryDark};

  position: absolute;
  z-index: 1;

  @media screen and (max-width: 680px) {
    width: 70vw;
    height: 480px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.graySecondaryDark};
    background: transparent;
    border: none;
  }
`;

export const MessageSection = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.grayPrimaryDark};
    }
  }

  p {
    margin-top: 5px;
  }

  & + div {
    margin-top: 30px;
  }
`;

export const MessageArea = styled.div`
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.grayPrimaryLight};
  }
`;
