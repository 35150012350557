import React, { useCallback, useState, useEffect, useRef } from 'react';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { MdOutlineCallEnd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { uuid } from 'uuidv4';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isSameHour, isSameMinute } from 'date-fns';
import { Client, Conversation } from '@twilio/conversations';
import { Channel } from 'twilio-chat/lib/channel';
import { Message } from 'twilio-chat/lib/message';
import {
  BsCameraVideo,
  BsCameraVideoOff,
  BsFillChatLeftTextFill,
  BsFillChatLeftFill,
} from 'react-icons/bs';
import {
  connect,
  Participant as ParticipantProps,
  Room as RoomVideoProps,
} from 'twilio-video';

import { useRouteRules } from '../../../hooks/routeRules';
import TeleConsultaService from '../../../services/TeleConsultaService';
import AgendamentoService from '../../../services/AgendamentoService';

import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Chat from './Chat';
import Participant from './Participant';

import {
  ToggleChat,
  Container,
  LocalVideoContainer,
  RemoteVideoContainer,
  MainArea,
  ControlsArea,
  NoVideo,
  ContainerVideo,
  ContainerTop,
  ContainerController,
  ContainerBtn,
  ContainerMedico,
  ContainerPaciente,
} from './styles';
import { Agendamento } from '../../../models/agendamento';
import { useAuth } from '../../../hooks/auth';
import CardGlobal from '../../../components/CardGlobal';
import chatNotificationSound from '../../../assets/sounds/chat-notification.mp3';
import { useSchedules } from '../../../hooks/local/useSchedules';
const notificationSound = new Audio(chatNotificationSound);

export interface MessageSectionProps {
  id: string;
  userId: string;
  hour: Date;
  messages: {
    id: string;
    message: string;
  }[];
}

interface RouteParams {
  agendamentoId: string;
}

export type UsersType = {
  professional?: string;
  [key: string]: string | undefined;
};

const Consulta: React.FC = () => {
  const history = useHistory();
  const { getSchedulesById, schedule, success } = useSchedules();
  const { params } = useRouteMatch<RouteParams>();
  const { verifyRules } = useRouteRules();
  const { agendamentoId } = params;
  const { signOut } = useAuth();
  const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [chatVisible, setChatVisible] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [chatToken, setChatToken] = useState<string | null>(null);
  const [roomVideo, setRoomVideo] = useState<RoomVideoProps | null>(null);
  const [videoParticipants, setVideoParticipants] = useState<
    ParticipantProps[]
  >([]);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [messages, setMessages] = useState<MessageSectionProps[]>([]);
  const [myConversation, setMyConversation] = useState<Conversation>();
  const [users, setUsers] = useState<UsersType>();

  const handleMessageAdded = (message: Message) => {
    if (!chatVisible) {
      setHasNewMessage(true);
    }
    setMessages(state => {
      const currentHour = new Date();

      if (
        state.length > 0 &&
        state[state.length - 1].userId === message.author &&
        isSameHour(state[state.length - 1].hour, currentHour) &&
        isSameMinute(state[state.length - 1].hour, currentHour)
      ) {
        const newState = state.slice(0, state.length - 1);

        const newMessages = {
          ...state[state.length - 1],
          messages: [
            ...state[state.length - 1].messages,
            {
              id: uuid(),
              message: message.body,
            },
          ],
        };

        return [...newState, newMessages];
      }
      const newState = [...state];

      newState.push({
        id: uuid(),
        userId: message.author,
        messages: [
          {
            id: uuid(),
            message: message.body,
          },
        ],
        hour: new Date(),
      });

      return newState;
    });
  };

  const sendMessage = useCallback(
    (message: string) => {
      if (myConversation) {
        myConversation.sendMessage(message).then(() => {});
      } else {
        toast.error('Não foi possível enviar a mensagem');
      }
    },
    [messages],
  );

  const createOrJoinConversation = async (client: Client) => {
    try {
      const uniqueName = `${params.agendamentoId}-chat`;
      const conversation = await client.getConversationByUniqueName(uniqueName);
      setMyConversation(conversation);
      await getMessages(conversation);
    } catch (err) {
      if ((err as any).status === 404 || (err as any).status === 403) {
        toast.error('Não foi possível criar ou entrar na conversa');
        console.log('err', err);
        const uniqueName = `${params.agendamentoId}-chat`;
        const conversation = await client.getConversationByUniqueName(
          uniqueName,
        );
        setMyConversation(conversation);
        await getMessages(conversation);
      }
    }
  };

  const getMessages = async (conversation: any) => {
    conversation.getMessages().then((msgs: any) => {
      const allMessages = msgs.items.map((msg: any) => {
        return {
          id: uuid(),
          userId: msg.author,
          messages: [
            {
              id: uuid(),
              message: msg.body,
            },
          ],
          hour: msg.dateCreated,
        };
      });
      setMessages(allMessages);
    });
  };

  useEffect(() => {
    getSchedulesById(agendamentoId);
  }, []);

  useEffect(() => {
    if (chatToken && users && schedule?.profissionalId) {
      const client = new Client(chatToken);
      client.on('connectionStateChanged', state => {
        switch (state) {
          case 'connected':
            createOrJoinConversation(client);
            break;
          case 'disconnecting':
            break;
          case 'disconnected':
            break;
          case 'denied':
            break;
        }
      });
      client.on('messageAdded', (message: any) => {
        handleMessageAdded(message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatToken, users, schedule?.profissionalId]);

  const participantConnected = (participant: ParticipantProps) => {
    setVideoParticipants(state => [...state, participant]);
  };

  const handleConnectCall = useCallback(
    async (token: string, roomName: string) => {
      connect(token, {
        name: roomName,
      })
        .then(room => {
          setRoomVideo(room);
          // console.log('room1', room);
          room.on('participantConnected', participant => {
            setVideoParticipants(state => [...state, participant]);
          });
          room.on('participantDisconnected', participant => {
            setVideoParticipants(state => state.filter(p => p !== participant));
            handleDisconnectCall();
            signOut();
          });
          // console.log('room2', room);
          room.participants.forEach(participant => {
            participantConnected(participant);
          });
          // console.log('room3', room);
        })
        .catch(() => toast.error('Não foi possível se conectar à chamada'));
    },
    [],
  );

  const handleDisconnectCall = useCallback(
    (redirectToAppointments?: boolean) => {
      if (roomVideo && roomVideo.localParticipant.state === 'connected') {
        roomVideo.localParticipant.tracks.forEach(trackPublication => {
          trackPublication.track;
        });
        roomVideo.disconnect();
      }

      if (redirectToAppointments) {
        history.push(`/area/agendamento`);
      }
    },
    [roomVideo, history],
  );

  const getTokenAndStartAppointment = useCallback(async () => {
    try {
      await TeleConsultaService.create(params.agendamentoId);

      const appointment = await TeleConsultaService.getTeleConsultaById(
        params.agendamentoId,
      );

      setToken(appointment.token);
    } catch {
      toast.error('Não foi possível se conectar à consulta');
    }
  }, [params.agendamentoId]);

  const getChatToken = useCallback(
    async pacienteId => {
      try {
        // GAMBIARRA PARA AGUARDAR O MEDICO CRIAR A CONVERSAÇÃO E EVITAR QUE O PACIENTE TENTE ENTRAR NA SALA ANTES DO MÉDICO
        // NÃO MEXA!!
        setTimeout(async () => {
          const token = await TeleConsultaService.getUserChatToken(pacienteId);
          setChatToken(token);
        }, 2000);
      } catch {
        toast.error('Não foi possível recuperar o token');
      }
    },
    [params.agendamentoId],
  );

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: [
        '/area/agendamento',
        '/area/agendamento/detalhes',
      ],
      onError: () => {
        // history.push('/area/agendamento');
      },
    });

    AgendamentoService.getById(params.agendamentoId).then(response => {
      setUsers({
        professional: response.profissional?.nome,
        [`${response.individuoId}`]: 'Você',
        [`${response.profissionalId}`]:
          response.profissional?.nome || 'Desconhecido',
      });
      getChatToken(response.individuoId);
    });

    TeleConsultaService.getTeleConsultaById(params.agendamentoId)
      .then(response => {
        setToken(response.token);
      })
      .catch(() => {
        getTokenAndStartAppointment();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && agendamentoId) {
      handleConnectCall(token, agendamentoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendamentoId, token]);

  useEffect(() => {
    return () => {
      handleDisconnectCall();
    };
  }, [handleDisconnectCall]);

  const handleToggleMicrophone = useCallback(() => {
    if (roomVideo) {
      roomVideo.localParticipant.audioTracks.forEach(publication => {
        if (microphoneEnabled) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }

        setMicrophoneEnabled(state => !state);
      });
    }
  }, [roomVideo, microphoneEnabled]);

  const handleToggleCamera = useCallback(() => {
    if (roomVideo) {
      roomVideo.localParticipant.videoTracks.forEach(publication => {
        if (cameraEnabled) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }

        setCameraEnabled(state => !state);
      });
    }
  }, [roomVideo, cameraEnabled]);

  const handleToggleChat = useCallback(() => {
    setHasNewMessage(false);
    setChatVisible(state => !state);
  }, []);

  useEffect(() => {
    hasNewMessage && !chatVisible
      ? notificationSound.play()
      : notificationSound.pause();
  }, [hasNewMessage, chatVisible]);

  return (
    <Container>
      <CardGlobal>
        <h1>Dr(a). {users?.professional}</h1>
        <ContainerVideo>
          <ContainerTop>
            {chatVisible && (
              <Chat
                messages={messages}
                sendMessage={sendMessage}
                users={users}
                closeChat={handleToggleChat}
              />
            )}

            <ContainerMedico>
              {videoParticipants.length === 1 ? (
                <Participant
                  key={videoParticipants[0].sid}
                  participant={videoParticipants[0]}
                  type="remote"
                  participantName={users?.professional}
                />
              ) : (
                <NoVideo videoType="local">
                  <span>Aguardando o médico...</span>
                </NoVideo>
              )}
            </ContainerMedico>

            <ContainerPaciente>
              {roomVideo ? (
                <Participant
                  key={roomVideo.localParticipant.sid}
                  participant={roomVideo.localParticipant}
                  type="local"
                />
              ) : (
                <></>
              )}
            </ContainerPaciente>
          </ContainerTop>

          <ContainerController>
            <ContainerBtn>
              <Button
                onClick={handleToggleMicrophone}
                btnType="gray"
                disabled={!roomVideo}
              >
                {microphoneEnabled ? <BiMicrophone /> : <BiMicrophoneOff />}
              </Button>
            </ContainerBtn>
            <ContainerBtn>
              <Button
                btnType="red"
                //disabled={!roomVideo}
                disabled={true}
                onClick={() => handleDisconnectCall(true)}
              >
                <MdOutlineCallEnd />
              </Button>
            </ContainerBtn>
            <ContainerBtn>
              <Button
                onClick={handleToggleCamera}
                btnType="gray"
                disabled={!roomVideo}
              >
                {cameraEnabled ? <BsCameraVideo /> : <BsCameraVideoOff />}
              </Button>
            </ContainerBtn>
            <ContainerBtn>
              <ToggleChat
                type="button"
                onClick={handleToggleChat}
                isActive={chatVisible}
                hasNewMessage={hasNewMessage && !chatVisible}
              >
                {chatVisible ? (
                  <BsFillChatLeftFill />
                ) : (
                  <BsFillChatLeftTextFill />
                )}
              </ToggleChat>
            </ContainerBtn>
          </ContainerController>
          <div>
            <audio
              src={chatNotificationSound}
              onPlay={() => hasNewMessage && !chatVisible}
            />
          </div>
        </ContainerVideo>
      </CardGlobal>
    </Container>
  );
};

export default Consulta;
