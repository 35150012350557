import { ApiObject } from '../models/apiObject';
import { Individuo } from '../models/individuo';
import { api } from './Api';

const create = async (individuo: Individuo): Promise<Individuo> => {
  const { data } = await api.post<Individuo>('Individuo', individuo);
  return data;
};

const getList = async (
  individuo: Individuo,
  skip = 1,
  take = 1000,
): Promise<Individuo[]> => {
  const { data } = await api.get<ApiObject<Individuo>>('Individuo', {
    params: { ...individuo, skip, take },
  });


  return data.items;
};

const getIndividuoByCpf = async (cpf: string): Promise<any> => {
  const { data } = await api.get<Individuo>(`Individuo?cpf=${cpf}`);
  return data;
};

const getById = async (id: string | undefined): Promise<Individuo> => {
  const { data } = await api.get<Individuo>(`Individuo/${id}`);
  return data;
};

const save = async (id: string, individuo: Individuo): Promise<Individuo> => {
  const { data } = await api.put<Individuo>(`Individuo/${id}`, individuo);
  return data;
};

export default {
  create,
  save,
  getList,
  getById,
  getIndividuoByCpf,
};
