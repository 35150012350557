/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import { shade } from 'polished';

export type btnColorType =
  | 'gray'
  | 'red'
  | 'primary'
  | 'outlineGray'
  | 'outlineRed'
  | 'outlinePrimary';

interface ContainerProps {
  btnType?: btnColorType;
  filled?: boolean;
  squared?: boolean;
  textSize?: number;
  $loading?: boolean;
}

type styleVariationsType = {
  [key in btnColorType]: any;
};

const styleVariations: styleVariationsType = {
  gray: css`
    color: #fff;
    border: none;
    background: ${({ theme }) => theme.colors.graySecondaryDark};

    &:hover {
      background: ${({ theme }) => shade(0.2, theme.colors.graySecondaryDark)};
      cursor: pointer;
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: #000;
    }
  `,
  red: css`
    color: #fff;
    border: none;
    background: ${({ theme }) => theme.colors.danger};

    &:hover {
      background: ${({ theme }) => shade(0.2, theme.colors.danger)};
    cursor: pointer;
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: #000;
    }
  `,
  primary: css`
    color: #fff;
    border: none;
    background: ${({ theme }) => theme.colors.primary};

    &:hover {
      background: ${({ theme }) => shade(0.2, theme.colors.primary)};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: #000;
    }
  `,
  outlineGray: css`
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    border: 2px solid ${({ theme }) => theme.colors.graySecondaryDark};
    background: #fff;

    &:hover {
      color: #fff;
      background: ${({ theme }) => theme.colors.graySecondaryDark};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      border: 2px solid ${({ theme }) => theme.colors.graySecondaryDark};
    }
  `,
  outlineRed: css`
    color: ${({ theme }) => theme.colors.danger};
    border: 2px solid ${({ theme }) => theme.colors.danger};
    background: #fff;

    &:hover {
      color: #fff;
      background: ${({ theme }) => theme.colors.danger};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      border: 2px solid ${({ theme }) => theme.colors.graySecondaryDark};
    }
  `,
  outlinePrimary: css`
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background: #fff;

    &:hover {
      color: #fff;
      background: ${({ theme }) => theme.colors.primary};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.graySecondaryLight};
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      border: 2px solid ${({ theme }) => theme.colors.graySecondaryDark};
    }
  `,
};

export const Container = styled.button<ContainerProps>`
  border-radius: ${({ squared }) => (squared ? 0 : '8px')};
  margin-top: 5px;
  padding: 12px 12px;
  width: 100%;
  font-size: ${({ textSize }) => (textSize ? `${textSize}px` : '1rem')};
  font-weight: 500;
  :hover{
    cursor: pointer;
  }

  ${({ btnType }) =>
    btnType ? styleVariations[btnType] : styleVariations.primary}

  &:disabled {
    cursor: pointer;
  }
`;
