import { Individuo } from '../models/individuo';
import { removeMask } from './removeMask';

export function formatIndividual(individual: Individuo): Individuo {
  const user = {
    ...individual,
    logradouroCep: removeMask(individual.logradouroCep as string),
    email: individual.email?.trim(),
    nomeCompleto: individual.nomeCompleto?.toUpperCase().trim(),
    nomeDaMae: individual.nomeDaMae?.toUpperCase().trim(),
    nomeDoPai: individual.nomeDoPai?.toUpperCase().trim(),
    nomeSocial: individual.nomeSocial?.toUpperCase().trim(),
    ufAbreviado: individual.ufAbreviado?.toUpperCase().trim(),
    ufDeNascimentoAbreviado: individual.ufDeNascimentoAbreviado
      ?.toUpperCase()
      .trim(),
    cpf: removeMask(individual.cpf as string),
    cns: removeMask(individual?.cns ?? ''),
    telefoneCelular: removeMask(individual.telefoneCelular as string),
    imagem: '',
  };
  return user;
}
