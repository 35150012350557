import styled, { css } from 'styled-components';

export interface ContainerProps {
  maxWidthDefault?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 30px;
  margin: 30px auto;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 20px;
    margin: 10px auto;
  }

  ${({ maxWidthDefault }) =>
    maxWidthDefault &&
    css`
      width: 100%;
      max-width: 1600px;
    `}
`;
