import styled from 'styled-components';
import theme from '../../styles/theme';

export const Container = styled.button`
  border: 1px solid #9999;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: ${theme.colors.primaryDark};
  overflow: hidden;
  width: 60%;
  height: 100px;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
`;

export const Header = styled.div`
  width: 100%;
  min-height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Title = styled.span`
  color: #fff;
  font-size: 12pt;
  font-weight: bold;
  overflow-x: hidden;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: #fff;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
`;

export const ExamType = styled.span`
  font-size: 10pt;
  color: #333;
  text-align: center;
  font-weight: bold;
`;

export const Size = styled.span`
  font-size: 8pt;
  color: #777;
  text-align: center;
`;

export const DataEnvio = styled.span`
  font-size: 9pt;
  color: #777;
  text-align: center;
`;
