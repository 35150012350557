export const grauDeInstrucao = [
  { label: 'Creche', key: 'CRECHE' },
  {
    label: 'Pré-Escola (Exceto CA)',
    key: 'PRE_ESCOLA_EXCETO_CA',
  },
  {
    label: 'Classe de alfabetização - CA',
    key: 'CLASSE_ALFABETIZADA_CA',
  },
  {
    label: 'Ensino Fundamental 1ª a 4ª séries',
    key: 'ENSINO_FUNDAMENTAL_1_4_SERIES',
  },
  {
    label: 'Ensino Fundamental 5ª a 8ª séries',
    key: 'ENSINO_FUNDAMENTAL_5_8_SERIES',
  },
  {
    label: 'Ensino Fundamental Completo',
    key: 'ENSINO_FUNDAMENTAL_COMPLETO',
  },
  {
    label: 'Ensino Fundamental Especial',
    key: 'ENSINO_FUNDAMENTAL_ESPECIAL',
  },
  {
    label: 'Ensino Fundamental EJA - Séries Iniciais (Supletivo 1ª a 4ª)',
    key: 'ENSINO_FUNDAMENTAL_EJA_SERIES_INICIAIS_SUPLETIVO_1_4',
  },
  {
    label: 'Ensino Fundamental EJA - Séries Finais (Supletivo 5ª a 8ª)',
    key: 'ENSINO_FUNDAMENTAL_EJA_SERIES_INICIAIS_SUPLETIVO_5_8',
  },
  {
    label: 'Ensino Médio, Médio 2º Ciclo (Científico, Técnico e etc)',
    key: 'ENSINO_MEDIO_MEDIO_2_CICLO',
  },
  {
    label: 'Ensino Médio Especial',
    key: 'ENSINO_MEDIO_ESPECIAL',
  },
  {
    label: 'Ensino Médio EJA (Supletivo)',
    key: 'ENSINO_MEDIO_EJA_SUPLETIVO',
  },
  {
    label: 'Superior, Aperfeiçoamento, Especialização, Mestrado, Doutorado',
    key: 'SUPERIOR_APERFEICOAMENTO_ESPECIALIZACAO_MESTRADO_DOUTORADO',
  },
  {
    label: 'Alfabetização para Adultos (Mobral, etc)',
    key: 'ALFABETIZACAO_PARA_ADULTROS_MOBRAL_ETC',
  },
  { label: 'Nenhum', key: 'NENHUM' },
];
