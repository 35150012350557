import React from 'react';

import { Container, Content } from './styles';

const TrustTerm: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Termo de Confiabilidade</h1>

        <p>
          Dou expressa e plena autorização, no uso da plataforma do MEEDS
          Telemedicina à qual irá disponibilizar profissionais médicos,
          inscritos no CRM, para realizar o atendimento de consulta a distância
          através de serviços eletrônicos.
        </p>

        <p>
          <strong>Declaro ter ciência que:</strong>
        </p>

        <ul>
          <li>
            O atendimento a distância é limitado por não permitir a realização
            do exame físico presencial. Poderão ser executadas algumas manobras
            de telepropedêutica, que é o exame físico à distância. Por isso é
            ainda mais importante informar todos os dados e informações
            possíveis, não omitindo nenhuma informação referente ao problema de
            saúde, colaborando assim para o bom aproveitamento desta consulta;
          </li>
          <li>
            Poderá ser necessário, a critério do médico, a realização de exames
            complementares para auxiliar no diagnóstico;
          </li>
          <li>
            Mesmo após a consulta a distância, poderá ser necessário o
            deslocamento do paciente ao consultório para um atendimento
            presencial. Podendo ainda ocorrer o encaminhamento do paciente ao
            serviço de pronto atendimento, a depender da hipótese diagnóstica;
          </li>
          <li>
            A consulta eletrônica a distância, tal qual a consulta presencial,
            tem seu tempo delimitado e não garante ao paciente o direito à
            disposição do médico em horário não acordado previamente entre as
            partes;
          </li>
          <li>
            A consulta será realizada pela plataforma determinada pelo médico ou
            pelo serviço de saúde, com dados armazenados em prontuário sob
            guarda do médico e cumprida as exigências preconizadas pelo Conselho
            Federal de Medicina;{' '}
          </li>
          <li>
            Para consultas por convênio médico, é necessário o encaminhamento de
            guias e informações com dados pessoais para as operadoras realizarem
            o pagamento;{' '}
          </li>
          <li>
            Para consultas particulares, os valores e forma de pagamento serão
            definidos e acordado antecipadamente pelo médico e paciente ou
            representante legal;
          </li>
        </ul>
        <ul>
          <p>
            <strong>Dos riscos, declaro:</strong>
          </p>
          <li>
            Que é de minha expressa e espontânea vontade passar informações
            médicas a meu respeito, através de meios de comunicação on-line (a
            distância), estando sujeito as seguintes situações: – Perda de
            conexão durante a teleconsulta; – Necessidade de nova conexão para
            continuidade da tele consulta; – Variação no estabelecimento da
            conexão;
          </li>
          <li>
            Que autorizo a gravação dessa consulta, a critério do médico e tenho
            ciência que as informações serão guardadas em prontuário médico,
            cujo sigilo e confidencialidade repousam nas regras consagradas do
            Código de Ética Médica, Resolução nº 2217/18 do Conselho Federal de
            Medicina;
          </li>
          <li>
            Ter lido e entendido as orientações contidas no presente
            instrumento, as quais entendi completamente e aceito, ciente que
            estão atendidas as exigências da Lei nº 8078/90, a inferir,
            portanto, meu expresso e pleno consentimento para a realização da
            teleconsulta.
          </li>
        </ul>
      </Content>
    </Container>
  );
};

export default TrustTerm;
