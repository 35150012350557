import styled, { css } from 'styled-components';

interface ContenntProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  strong {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const Content = styled.div<ContenntProps>`
  display: flex;
  align-items: center;
  margin-top: 5px;
  background: #fff;
  border-radius: 6px;
  padding: 12px 16px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};

  ${({ isErrored }) =>
    isErrored &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border-color: ${theme.colors.primary};
    `}

  input {
    width: 100%;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    font-weight: normal;
    font-size: 1rem;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0;
      border: 0 none;
    }
  }
`;
