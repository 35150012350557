import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import getValidationErrors from '../../../utils/getValidationErrors';

import ImgLogo from '../../../assets/atend-logo.png';
import ImgLoogo from '../../../assets/logo-footer-novetech-p.png';
import ImgLogoo from '../../../assets/logo-footer.png';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import InputPassword from '../../../components/InputPassword';

import { Container, Content, Logo } from './styles';
import { useAuth } from '../../../hooks/auth';
import { Notificacao } from '../../../models/notificacao';
import { LoginSchema } from '../../../validations/LoginSchema';
import LocalStorageKeyEnum from '../../../enums/LocalStorageKeyEnum';
import useStablishment from '../../../hooks/local/useStablishment';
import InputSelectAlternative from '../../../components/InputSelectAlternative';
import useSpecialty from '../../../hooks/local/useSpecialty';
import { IPicker } from '../../../models/IPicker';

interface FormData {
  cns: string;
  //password: string;
}

const Login: React.FC = () => {
  const [specialtyId, setSpecialtyId] = useState('');
  const [establishmentId, setEstablishment] = useState('');
  const history = useHistory();
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { getSpecialty, specialtyPicker } = useSpecialty();
  const {
    getStablishment,
    stablishmentPicker,
    loading: stablishmentLoading,
    error,
    success,
  } = useStablishment();

  const handleGetEstablishment = (newValue: IPicker) => {
    getSpecialty(newValue?.key as string);
    setEstablishment(newValue?.key as string);
  };

  const handleGetSpecialty = (newValue: IPicker) => {
    setSpecialtyId(newValue?.key as string);
  };

  const handleNext = useCallback(
    async (data: FormData) => {
      try {
        if (!specialtyId || !establishmentId) {
          toast.error('Preencha os campos de estabelecimento e especialidade!');
          return;
        }
        setLoading(true);
        formRef.current?.setErrors({});

        const LoginSchema = Yup.object().shape({
          cns: Yup.string().required('Preencha o CNS ou CPF.'),
        });
        await LoginSchema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          username: data.cns.replace(/[^0-9]/g, ''),
          stablishment: establishmentId,
          specialty: specialtyId,
          //password: data.password,
        });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.error(error.message);
        } else {
          const notFoundUser = (
            (error as AxiosError).response?.data as string
          ).includes('Verifique seu Usuário e Senha.');
          if (notFoundUser) {
            toast.warning('Você ainda não possui um cadastro.');
            history.push(
              `register?cpfOrCns=${formRef.current?.getFieldValue(
                'cns',
              )}&stablishment=${formRef.current?.getFieldValue(
                'stablishment',
              )}&specialty=${formRef.current?.getFieldValue('specialty')}`,
            );
            return;
          }
          toast.error(
            `Tivemos um problema. ${(error as AxiosError).response?.data}`,
          );
        }
      }
    },
    [history, signIn, establishmentId, specialtyId],
  );

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeyEnum.AccessToken)) {
      history.push('/area/principal');
    }
  }, [localStorage.getItem(LocalStorageKeyEnum.AccessToken)]);

  useEffect(() => {
    getStablishment();
  }, []);

  return (
    <Container>
      <Content>
        <Logo src={ImgLogo} alt="logo atend telemedicina" />

        <Form ref={formRef} onSubmit={handleNext}>
          <InputMask name="cns" title="CNS ou CPF" mask="999999999999999" />
          <InputSelectAlternative
            name="stablishment"
            title="Estabelecimento"
            options={stablishmentPicker}
            onPress={handleGetEstablishment}
          />
          <InputSelectAlternative
            name="specialty"
            title="Especialidade"
            options={specialtyPicker}
            onPress={handleGetSpecialty}
          />

          <Button type="submit" btnType="gray" loading={loading}>
            Entrar
          </Button>
        </Form>

        <div className="novetech" style={{ marginTop: 40 }}>
          <div>
            <img className="nov" src={ImgLogoo} alt="Logo da Nov" />
            <address className="loginendereco">
              <a href="http://www.novetech.com.br">www.novetech.com.br</a>
              <br />
              <p>contato@novetech.com.br</p>
            </address>
          </div>
          {/* <div>
            <img className="nove" src={ImgLoogo} alt="Logo da Nove" />
          </div> */}
        </div>
      </Content>
    </Container>
  );
};

export default Login;
