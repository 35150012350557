import React, { useEffect, useRef, useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { RiDashboardFill, RiCalendarLine } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import { IoIosPerson } from 'react-icons/io';
import { FiMenu } from 'react-icons/fi';
import { MdNotifications } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';

import ImgLogo from '../../assets/logo-horizontal.png';

import { colors } from '../../styles/theme/colors';

import {
  Container,
  Content,
  DropMenuProfile,
  Header,
  MenuProfileButton,
  Notifications,
  ProfileWeb,
  ProfileGreetings,
} from './styles';
import { useAuth } from '../../hooks/auth';

interface MainTemplateProps {
  withLateralMenu: boolean;
  withHeader: boolean;
  route: string;
}

const MainTemplate: React.FC<MainTemplateProps> = ({
  withLateralMenu,
  withHeader,
  route,
  children,
}) => {
  const { signOut, name } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const onResizeEvent = () => {
    const newWidth = containerRef.current?.offsetWidth;
    if (newWidth) {
      setIsCollapsed(newWidth <= 1200);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeEvent, true);

    onResizeEvent();
  }, []);

  const handleMenuItemActive = (itemValue: string[]): boolean => {
    return itemValue.includes(route);
  };

  return (
    <Container ref={containerRef} withHeader={withHeader}>
      {withHeader && (
        <Header withLateralMenu={withLateralMenu}>
          <div>
            <button >
              {/* <FiMenu color="#fff" size={25} /> */}
            </button>
            <button
              onClick={() => history.push('/area/principal')}
              type="button"
            >
              <img src={ImgLogo} alt="logo" />
            </button>
          </div>

          {withLateralMenu && (
            <>
              <Notifications
                type="button"
                hasNewNotifications={false}
                onClick={() => history.push('/area/notificacoes')}
              >
                <MdNotifications color="#fff" size={30} />
              </Notifications>
              <ProfileWeb>
                <CgProfile color="#fff" size={30} />
                {name && (
                  <ProfileGreetings>
                    <span>Olá,</span>
                    <strong>{name.split(' ')[0]}</strong>
                  </ProfileGreetings>
                )}
                <DropMenuProfile>
                  {/* <li>
                    <MenuProfileButton
                      onClick={() => history.push('/area/perfil')}
                    >
                      Perfil
                    </MenuProfileButton>
                  </li>
                  <li>
                    <MenuProfileButton
                      onClick={() =>
                        history.push('/area/questionarios/comorbidade')
                      }
                    >
                      Editar comorbidades
                    </MenuProfileButton>
                  </li>
                  <li>
                    <MenuProfileButton
                      onClick={() =>
                        history.push('/area/profile/alterar-senha')
                      }
                    >
                      Trocar senha
                    </MenuProfileButton>
                  </li> */}
                  <li>
                    <MenuProfileButton onClick={signOut}>
                      Sair
                    </MenuProfileButton>
                  </li>
                </DropMenuProfile>
              </ProfileWeb>
            </>
          )}
        </Header>
      )}
      <div>

        <Content>{children}</Content>
      </div>
    </Container>
  );
};

export default MainTemplate;
