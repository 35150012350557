import { api } from './Api';

const getCode = async (id: string, method: string): Promise<void> => {
  await api.get(`Individuo/${id}/CodigoAutenticacao?metodo=${method}`);
};

const confirmCode = async (id: string, code: string): Promise<void> => {
  await api.put(`Individuo/${id}/CodigoAutenticacao?codigo=${code}`);
};

export default { getCode, confirmCode };
