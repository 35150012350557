/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';

import { Select, Container } from './styles';
import { IPicker } from '../../models/IPicker';

interface Props extends SelectProps {
  name: string;
  title?: string;
  onPress?: (newValue: IPicker) => void;
  disabled?: boolean;
}

const InputSelectAlternative: React.FC<Props> = ({
  name,
  title,
  disabled = false,
  onPress,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.props.value) {
            return [];
          }

          return ref.props.value.map((option: any) => option.value);
        }
        if (!ref.props.value) {
          return '';
        }

        // Valor do input
        return ref.props.value.key;
      },
      setValue: (ref: any, value: any) => {
        if (value && rest.isMulti && Array.isArray(value)) {
          const items = ref.props.options.filter((option: any) =>
            value.includes(option.value),
          );
          ref.setValue(items);
        } else if (!value) {
          ref.clearValue();
        } else {
          const item = ref.props.options.filter(
            (option: any) => option.value === value,
          );
          if (item && item.length > 0) {
            ref.setValue(item[0]);
          } else {
            ref.clearValue();
          }
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, registerField]);

  return (
    <Container>
      <strong>{title}</strong>
      <Select
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={selectRef}
        classNamePrefix="react-select"
        isErrored={!!error}
        isFocused={isFocused}
        aria-haspopup="true"
        placeholder="Selecione..."
        isDisabled={disabled}
        onChange={newValue => onPress?.(newValue as IPicker)}
        {...rest}
      />
    </Container>
  );
};

export default InputSelectAlternative;
