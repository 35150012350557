import { useCallback, useState } from 'react';
import { ApiObject } from '../../models/apiObject';
import { Exame } from '../../models/IExams';
import { Individuo } from '../../models/individuo';
import ExameService from '../../services/ExameService';
import { useAuth } from '../auth';

interface UseExamsReturn {
  postExams: (exame: Exame[]) => Promise<void>;
  getExams: (
    dataDeEnvio?: string,
    nome?: string,
    tipoExameId?: string,
  ) => Promise<void>;
  error: string;
  success: string;
  exams: Exame[] | undefined;
  loading: boolean;
}

interface ExamsProps {
  skip: 1;
  take: 1000;
  sort: 'Nome ASC';
  dataDeEnvio?: string;
  nome?: string;
}

export function UseExams(): UseExamsReturn {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [exams, setExams] = useState<Exame[]>();
  const { id } = useAuth();

  const postExams = useCallback(async (exame: Exame[]) => {
    try {
      setLoading(true);
      setError('');
      await ExameService.post(exame);
      setSuccess('Exames enviados com sucesso.');
    } catch (err: any) {
      setSuccess('');
      setError('Não foi possível enviar os exames.');
    } finally {
      setLoading(false);
    }
  }, []);

  const getExams = useCallback(
    async (dataDeEnvio?: string, nome?: string, tipoExameId?: string) => {
      try {
        if (!id) return;
        setLoading(true);
        setError('');
        const Exams = await ExameService.get(
          id,
          dataDeEnvio ?? null,
          nome,
          tipoExameId,
        );
        setExams(Exams.items);
      } catch (err) {
        console.log(err);
        setError('Não foi possivel carregar os exames.');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { postExams, getExams, error, success, exams, loading };
}
