import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import InputPassword from '../../../../components/InputPassword';
import { useAuth } from '../../../../hooks/auth';
import { useProfile } from '../../../../hooks/local/useProfile';
import { useRouteRules } from '../../../../hooks/routeRules';
import { Notificacao } from '../../../../models/notificacao';
import IndividuoSenhaService from '../../../../services/IndividuoSenhaService';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { PasswordSchema } from '../../../../validations/PasswordSchema';
import { Container } from '../styles';
import { Content } from './styles';

interface FormData {
  password: string;
  repeat: string;
}

const ChooseOneResetPassword: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const { cpf } = useAuth();
  const { state } = useLocation();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { putPassword, loading, success, error } = useProfile();

  useEffect(() => {
    if (!success) return;
    toast.success('Senha alterada com sucesso');

    history.push('/area/principal');
  }, [success]);

  useEffect(() => {
    if (!error) return;
    toast.success('Erro ao alterar senha.');
  }, [error]);

  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        // const schema = Yup.object().shape({
        //   // oldPassword: Yup.string().required('A senha antiga é obrigatória'),
        //   newPassword: Yup.string()
        //     .required('A nova senha é obrigatória')
        //     .matches(
        //       /^(?=.*[0-9])(?=.*[a-z]).{8,}/,
        //       'Utilize pelo menos uma letra e um número.',
        //     ),
        //   repeat: Yup.string()
        //     .required('A nova senha é obrigatória')
        //     .matches(
        //       /^(?=.*[0-9])(?=.*[a-z]).{8,}/,
        //       'Utilize pelo menos uma letra e um número.',
        //     )
        //     .oneOf(
        //       [Yup.ref('newPassword'), undefined],
        //       'Confirmação incorreta',
        //     ),
        // });

        await PasswordSchema.validate(data, {
          abortEarly: false,
        });

        if (!cpf) {
          return;
        }

        // await IndividuoSenhaService.reset({
        //   codigoAutenticacao: btoa(state.codigoAutenticacao),
        //   senha: btoa(data.password),
        //   confirmacao: btoa(data.repeat),
        //   cpf: btoa(cpf),
        // });

        putPassword(
          btoa(cpf),
          btoa(state.codigoAutenticacao),
          btoa(data.password),
          btoa(data.repeat),
        );
        //toast.success('Senha alterada com sucesso');

        //history.push('/area/principal');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [cpf, history, state],
  );

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: [
        '/area/profile/alterar-senha/verificar/confirmar',
      ],
      onError: () => {
        history.push('/area/profile/alterar-senha');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Content>
        <h3>Alterar Senha</h3>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* <InputPassword name="oldPassword" title="Senha antiga" /> */}
          <InputPassword name="password" title="Nova senha" />
          <InputPassword name="repeat" title="Repetir nova senha" />
          <Button btnType="gray" type="submit" loading={loading}>
            Trocar Senha
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default ChooseOneResetPassword;
