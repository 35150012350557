import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  width: 100%;
  padding: 15px 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 30px 0;
  width: 100%;
  max-width: ${({ theme }) => theme.gridBreakpoints.xl};

  h1 {
    margin: 20px auto;
  }

  p {
    margin-bottom: 15px;
  }
  li {
    margin-bottom: 10px;
  }
`;
