/* eslint-disable */

import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import CardExame from '../../../components/CardExame';
import { Card, FormContent, Item } from './styles';
import CleanView from '../../../components/CleanView';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../hooks/auth';
import { UseExams } from '../../../hooks/local/useExams';
import { Exame } from '../../../models/IExams';
import { Container } from '../Exames/styles';
import { ExamsContainer } from './styles';
import moment from 'moment';
import Swal from 'sweetalert2';
import Input from '../../../components/Input';
import InputSelectExamsTypes from '../../../components/InputSelectExamsTypes';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '../../../components/Button';
import { FiFilter } from 'react-icons/fi';
import IndividuoService from '../../../services/IndividuoService';
import { setupMaster } from 'cluster';
import { Individuo } from '../../../models/individuo';
import { useExamsTypes } from '../../../hooks/local/useExamsTypes';
import { TipoExame } from '../../../models/ITipoExame';

const ExamsDone: React.FC = () => {
  const {
    getExams,
    exams,
    error: errorExam,
    success,
    loading: loadingExams,
  } = UseExams();
  const { getExamsTypes, error: errorExamType, type } = useExamsTypes();

  useEffect(() => {
    if (errorExam) {
      toast.error(errorExam);
    }
    console.log(errorExam);
  }, [errorExam]);

  useEffect(() => {
    if (errorExamType) {
      toast.error(errorExamType);
    }
    console.log(errorExamType);
  }, [errorExamType]);

  useEffect(() => {
    getExams();
  }, [getExams]);

  useEffect(() => {
    getExamsTypes();
  }, [getExamsTypes]);

  const handleFilter = useCallback(data => {
    if (data) {
      getExams(data.DataDeEnvio, data.Nome.trim(), data.TipoExameId);
    }
  }, []);

  return (
    <Container>
      <Card>
        <h1>Exames Enviados</h1>
        <ExamsContainer isLoading={loadingExams}>
          <FormContent onSubmit={handleFilter}>
            <Item gridArea="date">
              <Input name="DataDeEnvio" title="Data De Envio" type="date" />
            </Item>

            <Item gridArea="name">
              <Input name="Nome" title="Nome" type="text" />
            </Item>

            <Item gridArea="type">
              <InputSelectExamsTypes
                name="TipoExameId"
                title="Tipo De Exame"
                options={type}
              />
            </Item>

            <Item gridArea="button">
              <Button btnType="primary">Buscar</Button>
            </Item>
          </FormContent>
          {!loadingExams ? (
            exams ? (
              exams.map((exame, index) => {
                const typeExam = type?.filter(
                  type => type.id === exame.tipoExameId,
                );

                return (
                  <CardExame
                    examType={typeExam ? typeExam[0].nome : ''}
                    key={index}
                    name={exame.nome}
                    dataEnvio={`Date de envio: ${moment(
                      exame.dataDeEnvio,
                    ).format('DD/MM/YYYY')}`}
                    onClick={() => {
                      if (exame.pdfExame) {
                        // var win = window.open();
                        // win?.document.write(
                        //   '<iframe src="' +
                        //     exame.pdfExame +
                        //     '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
                        // );
                        Swal.fire({
                          width: '90%',
                          heightAuto: true,
                          showCloseButton: false,
                          showConfirmButton: false,
                          cancelButtonColor: '#ff0000',
                          showCancelButton: true,
                          cancelButtonText: 'Fechar',
                          html:
                            '<iframe src="' +
                            exame.pdfExame +
                            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:90vh;" allowfullscreen></iframe>',
                        });
                      }
                    }}
                  />
                );
              })
            ) : (
              <>
                <CleanView text="Nenhum exame encontrado." />
              </>
            )
          ) : (
            <Loading size={60} spinnerColor="gray" />
          )}
        </ExamsContainer>
      </Card>
    </Container>
  );
};

export default ExamsDone;
