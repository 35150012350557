import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import {
  Container,
  InputWithMask,
  SimpleInput,
  Select,
  RegisterForm,
  Title,
  Line,
  SubmitButton,
} from './styles';
import { grauDeInstrucao } from '../../../constants/grauDeInstrucao';
import { useCountry } from '../../../hooks/local/useCountry';
import { IPicker } from '../../../models/IPicker';
import { sexoOptions } from '../../../constants/sexoOptions';
import { racaOptions } from '../../../constants/racaOptions';
import { useCities } from '../../../hooks/local/useCities';
import { Individuo } from '../../../models/individuo';
import { formatIndividual } from '../../../utils/formtIndividual';
import getValidationErrors from '../../../utils/getValidationErrors';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { NewRegisterSchema } from '../../../validations/Register/NewRegister/NewRegisterSchema';
import { useProfile } from '../../../hooks/local/useProfile';
import { useHistory } from 'react-router-dom';
import useQuery from '../../../utils/query';
import { useAuth } from '../../../hooks/auth';
import { useGetCep } from '../../../hooks/local/useGetCep';

const Register: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [hasNoMother, setHasNoMother] = useState(false);
  const [hasNoFather, setHasNoFather] = useState(false);
  const [nacionalidade, setNacionalidade] = useState<number>();
  const [cep, setCep] = useState('');
  const query = useQuery();
  const history = useHistory();
  const { signIn } = useAuth();
  const {
    getCep,
    loading: cepLoading,
    error: cepError,
    cep: cepInformation,
  } = useGetCep();
  const {
    getCountries,
    countryList,
    loading: loadingCountry,
    error: errorCountry,
  } = useCountry();
  const {
    getCitiesByUf,
    citySelectItems,
    loading: loadingCities,
    error: errorCities,
  } = useCities();
  const { post, loading, error, successStatus } = useProfile();

  const handleHasNoMother = (data: IPicker) => {
    setHasNoMother(data?.key as boolean);
  };

  const handleHasNoFather = (data: IPicker) => {
    setHasNoFather(data?.key as boolean);
  };

  const handleNacionalidade = (data: IPicker) => {
    setNacionalidade(data?.key as number);
  };

  const handleCep = (value: string) => {
    setCep(value);
  };

  const handleUfNascimentoAbreviada = (data: string) => {
    data.length == 2 && getCitiesByUf(data);
  };

  useEffect(() => {
    nacionalidade == 3 && getCountries();
  }, [nacionalidade]);

  useEffect(() => {
    if (!cep || cep.length !== 9) return;
    getCep(cep);
  }, [cep]);

  useEffect(() => {
    if (!cepInformation) return;
    formRef.current?.setFieldValue('ufAbreviado', cepInformation.uf);
    formRef.current?.setFieldValue('cidadeId', cepInformation?.ibge);
    formRef.current?.setFieldValue('localidade', cepInformation?.localidade);
    formRef.current?.setFieldValue('logradouroBairro', cepInformation.bairro);
    formRef.current?.setFieldValue('logradouro', cepInformation.logradouro);
  }, [cepInformation]);

  useEffect(() => {
    const cpfOrCns = query.get('cpfOrCns');
    if (cpfOrCns?.length == 11) formRef.current?.setFieldValue('cpf', cpfOrCns);
    else formRef.current?.setFieldValue('cns', cpfOrCns);
  }, []);

  const onSubmit = async (data: Individuo) => {
    try {
      //console.log('Individuo: ', formatIndividual(data));

      formRef.current?.setErrors({});

      await NewRegisterSchema.validate(data, {
        abortEarly: false,
      });

      await post(formatIndividual(data));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error as Yup.ValidationError);
        formRef.current?.setErrors(errors);
        if ((error as Error).message.includes('errors occurred')) {
          const valueErrors = (error as Error).message.split(' ')[0];
          toast.error(`Ocorreram ${valueErrors} erros no formulário.`);
          return;
        }
        toast.error((error as Error).message);
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    if (!successStatus) return;
    const cpfOrCns = query.get('cpfOrCns');
    const estabelecimento = query.get('stablishment');
    const especialidade = query.get('specialty');
    toast.success('Cadastro realizado com sucesso!');
    signIn({
      username: cpfOrCns as string,
      stablishment: estabelecimento as string,
      specialty: especialidade as string,
    });
    //history.push('/login');
  }, [successStatus]);

  useEffect(() => {
    if (!error) return;
    toast.error(error);
  }, [error]);

  return (
    <Container>
      <RegisterForm ref={formRef} onSubmit={onSubmit}>
        <Title>Informações de identificação pessoal</Title>
        <Line>
          <SimpleInput
            name="nomeCompleto"
            title="Nome completo (Obrigatório)"
          />
          <SimpleInput name="nomeSocial" title="Nome social" />
        </Line>
        <Line>
          <InputWithMask
            name="cpf"
            title="CPF (Obrigatório ou CNS)"
            mask="999.999.999-99"
          />
          <InputWithMask
            name="cns"
            title="CNS (Obrigatório ou CPF)"
            mask="999 9999 9999 9999"
          />
        </Line>
        <Line>
          <SimpleInput
            name="dataNascimento"
            title="Data de nascimento (Obrigatório)"
            type="date"
          />
        </Line>
        <Line>
          <Select
            name="sexo"
            title="Sexo (Obrigatório)"
            options={sexoOptions}
          />
          <Select
            name="racaOuCor"
            title="Raça ou Cor (Obrigatório)"
            options={racaOptions}
          />
        </Line>
        <Title>
          Informações de endereço. O endereço não é obrigatório para o cadastro.
        </Title>
        <Line>
          <InputWithMask
            name="logradouroCep"
            title="CEP"
            mask="99999-999"
            onChange={e => handleCep(e.target.value)}
          />
          <InputWithMask name="ufAbreviado" title="UF" mask="aa" />
        </Line>
        <Line>
          <SimpleInput name="localidade" title="Cidade" />
          <SimpleInput name="logradouroBairro" title="Bairro" />
        </Line>
        <Line>
          <SimpleInput name="logradouro" title="Rua" />
        </Line>
        <Line>
          <SimpleInput name="logradouroNumero" title="Número" />
          <SimpleInput name="logradouroComplemento" title="Complemento" />
        </Line>
        <Title>Informações de familiares</Title>
        <Line>
          <Select
            name="temMaeDesconhecida"
            title="Tem mãe desconhecida? (Obrigatório)"
            onPress={handleHasNoMother}
            options={[
              { key: true, label: 'Sim' },
              { key: false, label: 'Não' },
            ]}
          />
          <Select
            name="temPaiDesconhecido"
            title="Tem pai desconhecido? (Obrigatório)"
            onPress={handleHasNoFather}
            options={[
              { key: true, label: 'Sim' },
              { key: false, label: 'Não' },
            ]}
          />
        </Line>
        <Line>
          {!hasNoMother && (
            <SimpleInput name="nomeDaMae" title="Nome da mãe (Obrigatório)" />
          )}
          {!hasNoFather && (
            <SimpleInput name="nomeDoPai" title="Nome do Pai (Obrigatório)" />
          )}
        </Line>
        <Title>Informações adicionais</Title>
        <Line>
          <SimpleInput name="email" title="Email (Obrigatório)" />
          <InputWithMask
            name="telefoneCelular"
            title="Celular (Obrigatório)"
            mask="(99) 9 9999-9999"
          />
        </Line>
        <Line>
          <Select
            name="frequentaEscola"
            title="Frequenta Escola? (Obrigatório)"
            options={[
              { key: true, label: 'Sim' },
              { key: false, label: 'Não' },
            ]}
          />
          <Select
            name="nacionalidade"
            title="Nacionalidade (Obrigatório)"
            onPress={handleNacionalidade}
            options={[
              { key: 1, label: 'Brasileiro' },
              { key: 2, label: 'Naturalizado' },
              { key: 3, label: 'Estrangeiro' },
            ]}
          />
        </Line>
        {nacionalidade == 1 && (
          <Line>
            <InputWithMask
              name="ufDeNascimentoAbreviado"
              title="UF do estado de nascimento (Obrigatório)"
              onChange={event =>
                handleUfNascimentoAbreviada(event.target.value)
              }
              mask="aa"
            />
            <Select
              title="Cidade de Nascimento (Obrigatório)"
              name="cidadeDeNascimentoIbge"
              isLoading={loadingCities}
              options={citySelectItems}
            />
          </Line>
        )}
        {nacionalidade == 2 && (
          <Line>
            <SimpleInput
              name="naturalizacaoData"
              title="Data da naturalização (Obrigatório)"
              type="date"
            />
            <InputWithMask
              title="Portaria de naturalização (Obrigatório)"
              name="naturalizacaoPortaria"
              mask="9999/99999999-*"
            />
          </Line>
        )}
        {nacionalidade == 3 && (
          <Line>
            <Select
              title="País de Nascimento (Obrigatório)"
              name="paisDeNascimento"
              isLoading={loadingCountry}
              options={countryList}
            />
            <SimpleInput
              title="Data de entrada no Brasil (Obrigatório)"
              name="dataEntradaNoPais"
              type="date"
            />
          </Line>
        )}
        <Line>
          <Select
            title="Grau de instrucao? (Obrigatório)"
            name="grauDeInstrucao"
            options={grauDeInstrucao}
          />
        </Line>
        <SubmitButton type="submit" btnType="gray" loading={loading}>
          Registrar
        </SubmitButton>
      </RegisterForm>
    </Container>
  );
};

export default Register;
