import { AxiosResponse } from 'axios';
import { ICep } from '../models/ICep';
import { api } from './Api';

const getCep = async (cep: string): Promise<ICep> => {
  const response = await api.get<unknown, AxiosResponse<ICep>>(
    `https://viacep.com.br/ws/${cep}/json`,
  );
  return response.data;
};

export { getCep };
