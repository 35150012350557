import { useState } from 'react';
import { getCep as getCepApi } from '../../services/CepService';
import { ICep } from '../../models/ICep';

const useGetCep = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [cep, setCep] = useState<ICep | undefined>();

  const getCep = async (cep: string) => {
    cep = cep.replace(/\D/g, '');
    if (cep.length < 8) return;
    try {
      setLoading(true);
      setError('');
      const response = await getCepApi(cep);
      setCep(response);
    } catch (e) {
      setError(e as string);
    } finally {
      setLoading(false);
    }
  };

  return {
    getCep,
    loading,
    error,
    cep,
  };
};

export { useGetCep };
