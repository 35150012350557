import { AxiosResponse } from 'axios';
import { ApiObject } from '../models/apiObject';
import { api } from './Api';
import IGetReturn from '../models/IGetReturn';
import { ICity } from '../models/ICity';

interface getListByParams {
  ibge?: string;
  uf?: string;
  city?: string;
}

interface getListByItemResponse {
  ibge: number;
  nome: string;
}

async function getCitiesByUf(
  uf: string,
  skip = 1,
  take = 1000,
): Promise<IGetReturn<ICity[]>> {
  const response = await api.get<unknown, AxiosResponse<IGetReturn<ICity[]>>>(
    `/cidade?ufAbreviado=${uf}`,
    {
      params: { skip, take },
    },
  );
  return response.data;
}

const getListBy = async ({
  uf,
  city,
  ibge,
}: getListByParams): Promise<getListByItemResponse[]> => {
  const { data } = await api.get<ApiObject<getListByItemResponse>>(`Cidade`, {
    params: {
      Nome: city ? `%${city}%` : undefined,
      UfAbreviado: uf,
      Ibge: ibge,
    },
  });

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getListBy };
export { getCitiesByUf };
