import { AxiosError } from 'axios';
import { useState } from 'react';
import { getCountries as getCountriesApi } from '../../services/CountryService';

interface UseCountryReturn {
  getCountries: () => void;
  countryList: any[];
  loading: boolean;
  error: string;
}

const useCountry = (): UseCountryReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [countryList, setCountryList] = useState<any[]>([]);

  const getCountries = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await getCountriesApi();
      //console.log('response', response);
      const list = response.items.map((obj: any) => ({
        key: obj.id,
        label: obj.nome,
      }));

      setCountryList(list);
    } catch (e) {
      setError(`Error: ${(e as AxiosError).response?.data}`);
    } finally {
      setLoading(false);
    }
  };

  return { getCountries, countryList, loading, error };
};

export { useCountry };
