import styled, { css } from 'styled-components';

interface ToggleChatProps {
  isActive: boolean;
  hasNewMessage: boolean;
}

export const Container = styled.div`
  display: flex;

  width: 100%;
  /* height: calc(100vh - 75px); */
  padding: 0 10px;

  > div {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1400px;
    height: min-content;
    margin: 15px auto;

    > h1 {
      padding-bottom: 1rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      padding: 20px;
    }
  }
`;

export const LocalVideoContainer = styled.div`
  background: ${({ theme }) => theme.colors.graySecondaryDark};
  border-radius: 5px 5px 0 0;
  height: min-content;
  width: 100%;
`;

export const RemoteVideoContainer = styled.div<{ canHide: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: min-content;
  min-height: 140px;
  width: 25%;

  position: absolute;
  top: -520px;
  right: -135px;

  border: none;

  ${({ canHide }) =>
    canHide &&
    css`
      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        display: none;
      }
    `}
`;

export const MainArea = styled.div<{ chatVisible: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  //height: 60%;
  width: 66%;
  max-width: 900px;
  position: relative;
  top: 110px;
  padding: 20px;
  margin: auto;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {

    position: relative;

    padding: ${({ chatVisible }) => (chatVisible ? '20px' : '0')};
  }
`;

export const ControlsArea = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 1.5rem;

  background-color: ${({ theme }) => theme.colors.graySecondaryLight};
  border-radius: 0 0 5px 5px;
  padding: 20px 0;

  svg {
    width: 30px;
    height: 30px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    >button{
      margin: 5px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    button {
      padding: 10px;
      min-width: 70px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    button {
      padding: 8px;
      min-width: 40px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: 4fr 1fr;
    gap: 0.5rem;

    > div {
      padding-left: 10px;

      &:first-child {
        display: none;
      }
    }
  }
`;

export const ToggleChat = styled.button<ToggleChatProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  background: none;
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 12px 12px;
  margin-top: 5px;
  background: #f4f4f4;
  border-radius: 6px;

  > svg > path {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.grayPrimaryDark : theme.colors.graySecondaryDark};
  }

  ${({ hasNewMessage, theme }) =>
    hasNewMessage &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background: ${theme.colors.warning};
        border-radius: 50%;
        margin-left: 25px;
        margin-bottom: 25px;

        @media screen and (max-width: ${theme.gridBreakpoints.sm}) {
          width: 10px;
          height: 10px;
          margin-left: 15px;
          margin-bottom: 15px;
        }
      }
    `};

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    position: initial;
  }
`;

export const NoVideo = styled.div<{ videoType: 'local' | 'remote' }>`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.graySecondaryDark};
  width: 100%;
  height: 400px;

  span {
    color: #fff;
    text-align: center;
  }

  ${({ videoType }) =>
    videoType === 'local' &&
    css`
      border-radius: 5px 5px 0 0;
    `};

  @media screen and (min-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    ${({ videoType }) =>
      videoType === 'local'
        ? css`
            height: 400px;
          `
        : css`
            height: 140px;
            border-radius: 5px;
            border: 1px solid #fff;
          `};
  }

  @media screen and (max-width: 420px) {
    height: 250px;
  }
`;

export const ContainerVideo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70vh;
  background: ${({ theme }) => theme.colors.graySecondaryDark};
  border-radius: 6px;
`;

export const ContainerTop = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  position: relative;
  gap: 20px;
  // Mobile
    @media (max-width: 820px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
`;

export const ContainerController = styled.div`
  display: flex;
  flex: 0.6;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: ${({ theme }) => theme.colors.graySecondaryLight};
  border-radius: 0 0 6px 6px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
`;

export const ContainerMedico = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 30px;
  @media (max-width: 820px) {
    margin-left: 0px;
  }
`;

export const ContainerPaciente = styled.div`
  display: flex;
  padding-right: 30px;
  flex: 0.3;
  @media (max-width: 820px) {
    width: 200px;
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
`;
