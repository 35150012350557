import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export interface ContainerProps {
  maxWidthInitial?: boolean;
  showButton?: boolean;
}

interface ExamsContainerProps {
  isLoading?: boolean | undefined;
}

interface ItemProps {
  gridArea: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  & > div {
    width: 100%;
    max-width: 700px;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      min-height: initial;
    }

    ${({ maxWidthInitial }) =>
      maxWidthInitial &&
      css`
        max-width: initial;
      `}

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
    }
  }
`;

export const ExamsContainer = styled.div<ExamsContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button:last-child {
    margin: 15px auto 0 auto;
  }
`;

export const Card = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }

  height: 100%;

  overflow: visible;
  background: #fff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 30px;
  margin: 30px auto;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 20px;
    margin: 10px auto;
  }
`;

export const Item = styled.div<ItemProps>`
  height: 100%;
  width: 100%;
  grid-area: ${({ gridArea }) => gridArea};
`;

export const FormContent = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'date name type'
    'button button button';
  grid-gap: 5px;
  width: 100%;

  padding: 15px;
  margin: 0 0 50px 0;

  button {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div,
    > button {
      width: 100%;
    }

    > div + div,
    > button {
      margin-top: 15px;
    }
  }
`;
