import { constants } from 'crypto';
import { useCallback, useState } from 'react';
import { ApiObject } from '../../models/apiObject';
import { Exame } from '../../models/IExams';
import { Individuo } from '../../models/individuo';
import ExameService from '../../services/ExameService';
import IndividuoCodigoAutenticacaoService from '../../services/IndividuoCodigoAutenticacaoService';
import IndividuoSenhaService from '../../services/IndividuoSenhaService';
import IndividuoService from '../../services/IndividuoService';
import { useAuth } from '../auth';
import { AxiosError } from 'axios';

interface UseProfileReturn {
  getByParams: (individuo: Individuo) => Promise<void>;
  getByCpf: (cpf: string) => Promise<void>;
  getCode: (id: string, metodo: string) => Promise<void>;
  confirmCode: (id: string, code: string) => Promise<void>;
  post: (individuo: Individuo) => Promise<void>;
  putPassword: (
    cpf: string,
    codigoAutenticacao: string,
    senha: string,
    confirmacao: string,
  ) => Promise<void>;
  error: string;
  successStatus: boolean;
  success: string;
  loading: boolean;
  individuo?: Individuo[];
  userRegister?: Individuo;
}

export function useProfile(): UseProfileReturn {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successStatus, setSuccessStatus] = useState<boolean>(false);

  const [success, setSuccess] = useState('');
  const [individuo, setIndividuo] = useState<Individuo[]>();
  const [userRegister, setUserRegister] = useState<Individuo>();

  const getByParams = useCallback(async (individuo: Individuo) => {
    try {
      setLoading(true);
      setError('');
      const response = await IndividuoService.getList(individuo);
      setIndividuo(response);
    } catch (err) {
      console.log(err);
      setError('Erro.');
    } finally {
      setLoading(false);
    }
  }, []);

  const getByCpf = useCallback(async (cpf: string) => {
    try {
      setLoading(true);
      setError('');
      const response = await IndividuoService.getIndividuoByCpf(cpf);
      setIndividuo(response.items);
    } catch (err) {
      console.log(err);
      setError('Erro.');
    } finally {
      setLoading(false);
    }
  }, []);

  const getCode = useCallback(async (id: string, metodo: string) => {
    try {
      setLoading(true);
      setError('');
      await IndividuoCodigoAutenticacaoService.getCode(id, metodo);
    } catch (err) {
      console.log(err);
      setError('Erro Código Autenticação.');
    } finally {
      setLoading(false);
    }
  }, []);

  const confirmCode = useCallback(async (id: string, code: string) => {
    try {
      setLoading(true);
      setError('');
      await IndividuoCodigoAutenticacaoService.confirmCode(id, code);
    } catch (err) {
      console.log(err);
      setError('Erro.');
    } finally {
      setLoading(false);
    }
  }, []);

  const post = useCallback(async (individuo: Individuo) => {
    try {
      setLoading(true);
      setSuccessStatus(false);
      const profile = await IndividuoService.create(individuo);
      if (!profile) return;
      const response = await IndividuoService.getIndividuoByCpf(
        profile.cpf ?? '',
      );

      const user = response.items[0];
      let params = {
        cpf: btoa(user.cpf),
        senha: btoa(user.cpf),
        confirmacao: btoa(user.cpf),
        codigoAutenticacao: btoa(user.codigoAutenticacao),
      };

      await IndividuoSenhaService.create(user.id, params);

      setUserRegister(response.items[0]);
      setSuccessStatus(true);
    } catch (err: any) {
      setError(
        `Erro ao cadastrar paciente. ${(err as AxiosError).response?.data}`,
      );
      setSuccessStatus(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const putPassword = useCallback(
    async (
      cpf: string,
      codigoAutenticacao: string,
      senha: string,
      confirmacao: string,
    ) => {
      try {
        setLoading(true);
        setError('');
        setSuccess('');
        setSuccessStatus(false);
        let info = { cpf, codigoAutenticacao, senha, confirmacao };
        await IndividuoSenhaService.reset(info);
        setSuccessStatus(true);
      } catch (err) {
        console.log(err);
        setError('Erro.');
        setSuccessStatus(false);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    post,
    getCode,
    confirmCode,
    getByParams,
    getByCpf,
    putPassword,
    error,
    success,
    successStatus,
    individuo,
    userRegister,
    loading,
  };
}
