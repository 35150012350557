import moment from 'moment';
moment.locale('pt-br');

export function isValidDate(date: string): boolean {
	const momentDate = moment(date, 'YYYY-MM-DD', true);

	if (momentDate.isValid()) {
		const today = moment();
		const years = today.diff(momentDate, 'years', true);

		if (years >= 0 && years < 150) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}
