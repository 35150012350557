/* eslint-disable */
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { FiFilter, FiX } from 'react-icons/fi';
import moment from 'moment';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import AgendamentoService from '../../../services/AgendamentoService';

import {
  Container,
  Header,
  Content,
  FilterContent,
  CardGlobal,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import CardInProgress from '../../../components/CardInProgress';
import { Notificacao } from '../../../models/notificacao';
import Loading from '../../../components/Loading';
import CleanView from '../../../components/CleanView';
import Input from '../../../components/Input';
import InputSelect from '../../../components/InputSelect';
import { Agendamento as AgendamentoModel } from '../../../models/agendamento';
import { useSchedules } from '../../../hooks/local/useSchedules';

interface AgendamentoDTO {
  id?: string;
  data?: string;
  tipo?: string;
  profissional?: string;
}

interface FilterDTO {
  date?: string;
  status?: string;
}

const Agendamento: React.FC = () => {
  const history = useHistory();
  const { id } = useAuth();
  const [loading, setLoading] = useState(true);
  const [agendamentoEmAndamento, setAgendamentoEmAndamento] =
    useState<AgendamentoModel>();
  const [agendamentos, setAgendamentos] = useState<AgendamentoDTO[]>([]);
  const [andamento, setAndamento] = useState(false); // <AgendamentoDTO> dados do agendamento em andamento
  const [filter, setFilter] = useState<FilterDTO>();
  const {
    error: errorSchedules,
    getSchedules,
    loading: loadingSchedules,
    scheduleItems,
    success: successSchedules,
  } = useSchedules();

  useEffect(() => {
    getSchedules(false);
  }, [getSchedules]);

  useEffect(() => {
    if (errorSchedules) {
      toast.error(errorSchedules);
    }
    console.log(errorSchedules);
  }, [errorSchedules]);

  const handleFilter = useCallback((data?: FilterDTO) => {
    if (data?.status === '') {
      getSchedules(null, data?.date);
    } else {
      if (data?.status === '0') {
        getSchedules(false, data?.date);
      } else {
        getSchedules(true, data?.date);
      }
    }
  }, []);

  return (
    <Container>
      <CardGlobal maxWidthDefault>
        <div>
          {/* <Header>
            {andamento && (
              <CardInProgress
                date={agendamentoEmAndamento?.dia}
                hour={agendamentoEmAndamento?.hora}
                profissional={agendamentoEmAndamento?.profissional?.nome}
                specialty={agendamentoEmAndamento?.procedimento?.descricao}
              />
            )} 
             <Button
              btnType="outlinePrimary"
              onClick={() => history.push('/area/agendamento/novo')}
            >
              <MdOutlineEditCalendar size={20} />
              <span>Consultas pré-autorizadas</span>
            </Button> 
          </Header> */}
          <h3>Filtros</h3>
          <FilterContent onSubmit={handleFilter}>
            <Input name="date" title="Dia" type="date" disabled={!!filter} />
            <InputSelect
              name="status"
              title="Status"
              disabled={!!filter}
              options={[
                { label: 'Próximos atendimentos', value: '0' },
                { label: 'Atendimentos finalizados', value: '1' },
              ]}
            />
            <Button type="submit" btnType={filter ? 'red' : 'primary'}>
              Buscar
            </Button>
          </FilterContent>
          <h3>Agendamentos</h3>
          <Content>
            {!loadingSchedules ? (
              <>
                {scheduleItems.length > 0 ? (
                  <Table
                    header={{
                      Dia: 'data',
                      Hora: 'hora',
                      Médico: 'profissional',
                      Especialidade: 'especialidade',
                    }}
                    data={scheduleItems}
                    rowKey="id"
                  />
                ) : (
                  <CleanView text="Nenhum agendamento encontrado" />
                )}
              </>
            ) : (
              <Loading size={60} spinnerColor="gray" />
            )}
          </Content>
        </div>
      </CardGlobal>
    </Container>
  );
};

export default Agendamento;
