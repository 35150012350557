import styled from 'styled-components';
import { Form } from '@unform/web';
import InputMask from '../../../components/InputMask';
import Input from '../../../components/Input';
import InputSelectAlternative from '../../../components/InputSelectAlternative';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const InputWithMask = styled(InputMask)`
  width: 100%;
`;

export const SimpleInput = styled(Input)`
  width: 100%;
`;

export const Select = styled(InputSelectAlternative)``;

export const RegisterForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-bottom: 50px;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 2rem;
  margin: 60px 0 20px 0;
  color: #353535;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 10px;
  gap: 10px;
  @media (max-width: 820px) {
    flex-direction: column;
    margin-bottom: -10px;
  }
`;

export const SubmitButton = styled(Button)`
  @media (max-width: 820px) {
    margin-top: 20px;
  }
`;
