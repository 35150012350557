import { AxiosResponse } from 'axios';
import IGetReturn from '../models/IGetReturn';
import { api } from './Api';

const getCountries = async (): Promise<IGetReturn<any>> => {
  const response = await api.get<unknown, AxiosResponse<IGetReturn<any>>>(
    '/pais',
  );
  return response.data;
};

export { getCountries };
