import { useCallback, useState } from 'react';
import { getCitiesByUf as getCitiesByUfApi } from '../../services/CityService';
import { handleGetErrorMessage } from '../../utils/handleGetErrorMessage';

interface IPicker {
  key: string | number;
  label: string;
}

interface UseCityReturn {
  getCitiesByUf: (uf: string) => Promise<void>;
  citySelectItems: IPicker[];
  loading: boolean;
  error: string;
  success: string;
}

const useCities = (): UseCityReturn => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [citySelectItems, setCitySelectItems] = useState<IPicker[]>([]);

  const getCitiesByUf = useCallback(async (uf: string | undefined) => {
    if (!uf) return;
    try {
      setLoading(true);
      setError('');
      const city = await getCitiesByUfApi(uf);

      if (!city) {
        throw new Error('Cidade não encontrada');
      }
      setCitySelectItems(
        city?.items.map(item => ({
          key: item.ibge,
          label: item.nome,
        })),
      );
    } catch (e) {
      setSuccess('');
      setError(handleGetErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, []);

  return { getCitiesByUf, citySelectItems, loading, error, success };
};

export { useCities };
