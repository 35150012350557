import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../../../utils/getValidationErrors';

import InputMask from '../../../../components/InputMask';
import Button from '../../../../components/Button';

import { Container, Content } from '../styles';

interface FormData {
  cpf: string;
}

const CPFForgotPassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string()
            .required('O CPF é obrigatório.')
            .length(14, 'Digite um CPF válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        history.push('/esqueci-minha-senha/verificar', {
          cpf: data.cpf.replace(/[^0-9]/g, ''),
        });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <h2>Informe seu CPF para recuperação de senha:</h2>
        <p>
          Será enviado um código de autenticação por comunicação já existente
        </p>

        <Form ref={formRef} onSubmit={handleNext}>
          <InputMask name="cpf" mask="999.999.999-99" />

          <Button type="submit" btnType="gray" loading={loading}>
            Próximo
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CPFForgotPassword;
