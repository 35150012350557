import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;

  background: #fff;
  border-radius: 6px;
  padding: 14px 16px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};

  ${({ isErrored }) =>
    isErrored &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border-color: ${theme.colors.primary};
    `}

  select {
    flex: 1;
    width: 100%;
    border: 0;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    background: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0em top 50%;
    background-size: 0.65em auto;

    &:disabled {
      opacity: 0.7;
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }
  }
`;
