import * as yup from 'yup';
import { isValidDate } from '../../../utils/isValidDate';
import {
  INVALID_CNS,
  INVALID_CPF,
  INVALID_DATE,
  INVALID_EMAIL,
  INVALID_NAME,
  MAX_LENGTH_CEL,
  MIN_LENGTH_CEL,
  REQUIRED,
} from '../../../constants/error';
import { isValidEmail } from '../../../utils/isValidEmail';
import { isValidName } from '../../../utils/isValidName';
import { isValidCNS } from '../../../utils/isValidCNS';
import { isValidCPF } from '../../../utils/isValidCPF';

export const NewRegisterSchema = yup.object().shape({
  nomeCompleto: yup.string().test('isValidName', INVALID_NAME, value => {
    if (value?.length) return isValidName(value);
    return false;
  }),

  nomeSocial: yup.string(),

  //cpf ou cns vai ficar oculto caso ele preencha na validação inicial
  cns: yup.string().test('isValidCNS', INVALID_CNS, value => {
    if (value?.length) return isValidCNS(value);
    return true;
  }),

  cpf: yup
    .string()
    .test('isValidCPF', INVALID_CPF, value => {
      if (value?.length) return isValidCPF(value);
      return true;
    })
    .required(REQUIRED),

  pisPasep: yup.string(),
  sexo: yup.number().required(REQUIRED),
  racaOuCor: yup.number().when([], {
    is: () => true,
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),
  dataNascimento: yup
    .string()
    .test('isValidDate', INVALID_DATE, value => isValidDate(value as string))
    .required(REQUIRED),
  imagem: yup.string(),
  logradouro: yup.string(),
  logradouroNumero: yup.string(),
  logradouroComplemento: yup.string(),
  logradouroCep: yup.string(),
  logradouroBairro: yup.string(),
  ufAbreviado: yup.string(),
  localidade: yup.string(),
  cidadeId: yup.number(),
  nomeDoPai: yup.string().when('temPaiDesconhecido', {
    is: (val: boolean) => {
      //se o campo não tiver valor ou for false, retorna true, senão retorna false
      return val == undefined || val == false ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),
  nomeDaMae: yup.string().when('temMaeDesconhecida', {
    is: (val: boolean) => {
      //se o campo não tiver valor ou for false, retorna true, senão retorna false
      return val == undefined || val == false ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),
  temPaiDesconhecido: yup.boolean().required(REQUIRED),
  temMaeDesconhecida: yup.boolean().required(REQUIRED),
  email: yup
    .string()
    .test('isValidEmail', INVALID_EMAIL, value => {
      if (value?.length) return isValidEmail(value);
      return false;
    })
    .required(REQUIRED),

  telefoneCelular: yup
    .string()
    .min(16, MIN_LENGTH_CEL)
    .max(16, MAX_LENGTH_CEL)
    .required(REQUIRED),

  frequentaEscola: yup.boolean().when([], {
    is: () => true,
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  nacionalidade: yup.number().when([], {
    is: () => true,
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  ufDeNascimentoAbreviado: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 1 ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  cidadeDeNascimentoIbge: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 1 ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  naturalizacaoData: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 2 ? true : false;
    },
    then: schema =>
      schema
        .test('isValidDate', INVALID_DATE, value =>
          isValidDate(value as string),
        )
        .required(REQUIRED),
    otherwise: schema => schema,
  }),

  naturalizacaoPortaria: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 2 ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  paisDeNascimento: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 3 ? true : false;
    },
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),

  dataEntradaNoPais: yup.string().when('nacionalidade', {
    is: (val: number) => {
      return val == 3 ? true : false;
    },
    then: schema =>
      schema
        .test('isValidDate', INVALID_DATE, value =>
          isValidDate(value as string),
        )
        .required(REQUIRED),
    otherwise: schema => schema,
  }),

  grauDeInstrucao: yup.string().when([], {
    is: () => true,
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema,
  }),
});
