import { AxiosResponse } from 'axios';
import { extname } from 'path';
import { ApiObject } from '../models/apiObject';
import { Exame } from '../models/IExams';
import { Individuo } from '../models/individuo';
import { api } from './Api';

async function get(
  individuoId: string,
  dataDeEnvio?: string | null,
  nome?: string,
  tipoExameId?: string,
): Promise<ApiObject<any[]>> {
  const response = await api.get<unknown, AxiosResponse<ApiObject<any[]>>>(
    '/Exames/GetExames/',
    {
      params: {
        skip: 1,
        take: 100,
        sort: 'Nome ASC',
        individuoId,
        dataDeEnvio,
        nome,
        tipoExameId,
      },
    },
  );
  return response.data;
}

async function post(exame: Exame[]): Promise<any> {
  const response = await api.post<unknown, AxiosResponse<any>>(
    `Exames/ReceberExames`,
    exame,
  );
  return response;
}
export default { post, get };
