import { ApiObject } from '../models/apiObject';
import { IndividuoNotificacao } from '../models/individuoNotificacao';
import { api } from './Api';

const getAll = async (id: string): Promise<IndividuoNotificacao[]> => {
  const { data } = await api.get<ApiObject<IndividuoNotificacao>>(
    `/Notificacao`,
    {
      params: {
        individuoId: id,
      },
    },
  );

  return data.items;
};

export default { getAll };
