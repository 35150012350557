/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { FiFile } from 'react-icons/fi';

import {
  Container,
  Header,
  Body,
  Title,
  ContentInfo,
  Size,
  DataEnvio,
  ExamType,
} from './styles';

interface CardExame {
  dataEnvio?: string;
  size?: number | string;
  name?: string;
  examType?: string;
  onClick?: (e: any) => void;
}

const CardExame: React.FC<CardExame> = props => {
  const [sizeFile, setSizeFile] = useState<any>();
  const [name, setName] = useState<string>();
  const [examType, setExamType] = useState<string>();
  const [dataEnvio, setDataEnvio] = useState<string>();

  useEffect(() => {
    setExamType(props.examType);
    setName(props.name);
    setSizeFile(props.size);
    setDataEnvio(props.dataEnvio);
  }, []);

  return (
    <Container onClick={props.onClick}>
      <Header>
        <Title>{name}</Title>
      </Header>

      <Body onClick={props.onClick}>
        {/* <FiFile size={60} color="#f66" /> */}
        <ContentInfo>
          <ExamType>{examType}</ExamType>
          {sizeFile && <Size>{sizeFile} MB</Size>}
          <DataEnvio>{dataEnvio}</DataEnvio>
        </ContentInfo>
      </Body>
    </Container>
  );
};

export default CardExame;
