import styled from 'styled-components';

interface ItemProps {
  gridArea: string;
}

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    display: flex;
    flex-direction: column;

    width: 100%;

    form {
      width: 100%;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  > button {
    border: none;
    background: transparent;
    display: flex;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    font-size: 1rem;
    align-items: center;

    > svg {
      margin-right: 0.5rem;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr 2fr;
  grid-gap: 20px;
  grid-template-areas:
    'nome nome nome nome nome nome'
    'cpf cpf cns cns nomeSocial nomeSocial'
    'nomeDaMae nomeDaMae dataNascimento dataNascimento telefoneCelular telefoneCelular'
    'email email sexo sexo racaOuCor racaOuCor'
    'logradouroCep UFabreviado logradouroCidade logradouroCidade logradouroBairro logradouroBairro'
    'logradouro logradouro logradouroNumero logradouroComplemento logradouroComplemento logradouroComplemento';

  padding-bottom: 30px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.xl}) {
    grid-template-columns: 3fr 3fr 1fr 1fr 1fr 3fr;
    grid-template-areas:
      'nome nome nome nome nome nome'
      'nomeSocial nomeSocial nomeSocial nomeSocial nomeSocial nomeSocial'
      'cpf cpf cpf cpf cpf cpf'
      'cns cns cns cns cns cns'
      'dataNascimento dataNascimento dataNascimento dataNascimento dataNascimento dataNascimento'
      'email email email email email email'
      'telefoneCelular telefoneCelular telefoneCelular telefoneCelular telefoneCelular telefoneCelular'
      'sexo sexo sexo racaOuCor racaOuCor racaOuCor'
      'nomeDaMae nomeDaMae nomeDaMae nomeDaMae nomeDaMae nomeDaMae'
      'logradouroCep logradouroCep logradouroCep logradouroCep logradouroCep logradouroCep'
      'UFabreviado UFabreviado UFabreviado UFabreviado UFabreviado UFabreviado'
      'logradouroCidade logradouroCidade logradouroCidade logradouroCidade logradouroCidade logradouroCidade'
      'logradouroBairro logradouroBairro logradouroBairro logradouroBairro logradouroBairro logradouroBairro'
      'logradouro logradouro logradouro logradouro logradouro logradouro'
      'logradouroNumero logradouroNumero logradouroNumero logradouroNumero logradouroNumero logradouroNumero'
      'logradouroComplemento logradouroComplemento logradouroComplemento logradouroComplemento logradouroComplemento logradouroComplemento';
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Item = styled.div<ItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  width: 100%;
`;

export const ButtonArea = styled.div`
  & > h4 {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  & > div {
    display: flex;
    margin: 20px 0 30px;

    button + button {
      margin-left: 10px;
    }

    > button {
      min-width: 120px;
    }
  }

  & > button {
    min-width: 250px;
  }
`;
