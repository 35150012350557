/* eslint-disable */
import { AxiosResponse } from 'axios';
import { Client } from '@twilio/conversations';
import { Agendamento } from '../models/agendamento';
import { ApiObject } from '../models/apiObject';
import { api } from './Api';
import TeleConsultaService from './TeleConsultaService';

interface Params {
  skip?: number;
  take?: number;
  id?: string | undefined;
  finalizado?: boolean | undefined;
  cancelado?: boolean | undefined;
  dia?: string;
}

interface ITwilioConversation {
  token: string;
  individuoId: string;
  profissionalId: string;
  agendamentoId: string;
}

// async function get(
//   skip = 1,
//   take = 10,
//   individuoId: string | undefined,
//   finalizado: boolean | undefined,
//   cancelado: boolean | undefined,
//   inProgress?: boolean,
// ): Promise<ApiObject<Agendamento[]>> {
//   const response = await api.get<
//     unknown,
//     AxiosResponse<ApiObject<Agendamento[]>>
//   >('/agendamento', {
//     params: {
//       skip,
//       take,
//       individuoId,
//       finalizado,
//       cancelado,
//       emandamento: inProgress,
//       sort: 'dia',
//     },
//   });
//   return response.data;
// }

// const getByParams = async (
//   individuoId: string | undefined,
//   exameFinalizado?: string | undefined,
//   dia?: string,
//   emAndamento?: boolean,
// ): Promise<Agendamento[]> => {
//   const finalizado = exameFinalizado === '0' ? false : true;
//   const response = await api.get<ApiObject<Agendamento[]>>('/agendamento', {
//     params: {
//       skip: 1,
//       take: 10,
//       individuoId,
//       sort: 'Nome ASC',
//       finalizado,
//       cancelado: false,
//       dia,
//       emAndamento,
//     },
//   });
//   console.log('response', response);
//   return response.data.items;
// };

const get = async ({
  id,
  dia,
  finalizado,
  cancelado = false,
}: Params): Promise<Agendamento[]> => {
  const response = await api.get<ApiObject<Agendamento>>('/Agendamento', {
    params: {
      individuoId: id,
      dia,
      finalizado,
      cancelado,
    },
  });
  return response.data.items;
};

const getById = async (id: string): Promise<Agendamento> => {
  const { data } = await api.get<Agendamento>(`/Agendamento/${id}`);
  return data;
};

const update = async (
  id: string | undefined,
  agendamento: Agendamento,
): Promise<Agendamento> => {
  const response = await api.put<unknown, AxiosResponse<Agendamento>>(
    `/agendamento/${id}`,
    agendamento,
  );
  return response.data;
};

const createOrJoinConversation = async (chatObj: ITwilioConversation) => {
  const client = new Client(chatObj.token);
  client.on('connectionStateChanged', async (state: any) => {
    switch (state) {
      case 'connected':
        const uniqueName = `${chatObj.agendamentoId}-chat`;
        const activeConnection = await client.createConversation({
          uniqueName: uniqueName,
        });
        // const joinedConversation = await activeConnection.join();
        //await activeConnection.add(chatObj.individuoId);
        //await activeConnection.add(chatObj.profissionalId);
        break;
    }
  });
};

const create = async (agendamento: Partial<Agendamento>): Promise<string> => {
  const tokenTW = await TeleConsultaService.getUserChatToken(
    agendamento.individuoId as string,
  );

  const { data: id } = await api.post<string>('Agendamento', agendamento);

  let chatObj: ITwilioConversation = {
    token: tokenTW,
    individuoId: agendamento.individuoId as string,
    profissionalId: agendamento.profissionalId as string,
    agendamentoId: id as string,
  };

  //await createOrJoinConversation(chatObj);

  return id;
};

export default { get, getById, create, update };
