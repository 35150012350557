import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiEdit2 } from 'react-icons/fi';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Individuo } from '../../../models/individuo';
import IndividuoService from '../../../services/IndividuoService';
import getValidationErrors from '../../../utils/getValidationErrors';
import { putCEPMask, putCPFMask } from '../../../utils/hiddenData';

import { TextInput } from '../../../components/Form/CustomInput';
import InputSelect from '../../../components/Form/InputSelect';
import { RegisterSchema } from '../../../validations/Register/CompleteRegister/CompleteRegisterSchema';
import {
  breedList,
  genreList,
  FormData,
} from '../../../validations/Register/CompleteRegister/CompleteRegisterSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import { InputSelectOptions } from '../../../components/InputSelect';
import Button from '../../../components/Button';
import CardGlobal from '../../../components/CardGlobal';

import { CardHeader, Container, Content, Item } from './styles';
import { Notificacao } from '../../../models/notificacao';
import { useAuth } from '../../../hooks/auth';
import useAddress from '../../../hooks/local/useAddress';
import InputSelectAlternative from '../../../components/InputSelectAlternative';
import { getPartiallyHiddenString } from '../../../utils/getPartiallyHiddenString';

const Perfil: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useAuth();
  const [userScreen, setUserScreen] = useState<Individuo>();
  const {
    getCEP,
    UfList,
    cityList,
    updateUFList,
    updateCityList,
    getCityByID,
  } = useAddress();
  const [canEdit, setCanEdit] = useState(false);
  const [dontEdit, setDontEdit] = useState(true);
  const [newProfile, setNewProfile] = useState<any>();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>(/* { resolver: yupResolver(RegisterSchema) } */);
  const cep = watch('logradouroCep');

  const handleEdit = () => {
    if (!userScreen) return;
    transformIndividuoToFormData(userScreen);
    setCanEdit(true);
  };

  const transformIndividuoToFormData = useCallback(async (user: Individuo) => {
    setValue('logradouroNumero', user.logradouroNumero ?? '');
    setValue('logradouroComplemento', user.logradouroComplemento ?? '');
    setValue('logradouroCep', user.logradouroCep ?? '');
    setValue('logradouroBairro', user.logradouroBairro ?? '');
    setValue('logradouro', user.logradouro ?? '');

    setValue('cpf', user.cpf ?? '');
    setValue('nomeCompleto', user.nomeCompleto ?? '');
    setValue('nomeSocial', user.nomeSocial ?? '');
    setValue('dataNascimento', (user.dataNascimento as string).substr(0, 10));
    setValue('email', user.email ?? '');
    setValue('telefoneCelular', user.telefoneCelular ?? '');
    setValue('sexo', user.sexo == 0 ? 1 : user.sexo ?? 0);
    setValue('racaOuCor', user.racaOuCor ?? 1);
    setValue('nomeDaMae', user.nomeDaMae ?? '');
    if (user.logradouroCep) {
      const endereco = await getCEP(user.logradouroCep);
      setValue('UFabreviado', endereco.uf ?? '');
      setValue('logradouroCidade', endereco.localidade ?? '');
    }
  }, []);

  const hideUserData = useCallback((data: Individuo) => {
    setValue('cpf', putCPFMask(data.cpf));
    //setValue('logradouroCep', putCEPMask(data.logradouroCep));
    setValue('nomeCompleto', getPartiallyHiddenString(data.nomeCompleto ?? ''));
    setValue('email', getPartiallyHiddenString(data.email ?? ''));
    setValue(
      'telefoneCelular',
      getPartiallyHiddenString(data.telefoneCelular ?? ''),
    );
    setValue('nomeDaMae', getPartiallyHiddenString(data.nomeDaMae ?? ''));
    setValue(
      'logradouroBairro',
      getPartiallyHiddenString(
        !data.logradouroBairro ? '' : data.logradouroBairro,
      ),
    );
    setValue(
      'logradouro',
      getPartiallyHiddenString(!data.logradouro ? '' : data.logradouro),
    );
    setValue(
      'logradouroComplemento',
      getPartiallyHiddenString(
        !data.logradouroComplemento ? '' : data.logradouroComplemento,
      ),
    );
  }, []);

  const handleRegister = useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        if (!id) {
          return;
        }
        // const newUser: Individuo = {
        //   imagem: '',
        //   cns: data.cns,
        //   telefoneCelular: data.telefoneCelular,
        //   cpf: data.cpf,
        //   nomeCompleto: data.nomeCompleto,
        //   nomeSocial: data.nomeSocial,
        //   dataNascimento: data.dataNascimento,
        //   email: data.email,
        //   racaOuCor: data.racaOuCor,
        //   nomeDaMae: data.nomeDaMae,
        //   logradouroCep: data.logradouroCep?.replaceAll('-', ''),
        //   ufAbreviado: data.UFabreviado,
        //   logradouroCidade: data.logradouroCidade,
        //   logradouroBairro: data.logradouroBairro,
        //   logradouro: data.logradouro,
        //   logradouroNumero: data.logradouroNumero,
        //   logradouroComplemento: data.logradouroComplemento,
        // };
        const newUser = {
          ...newProfile,
          cns: data.cns,
          telefoneCelular: data.telefoneCelular,
          cpf: data.cpf,
          nomeCompleto: data.nomeCompleto,
          nomeSocial: data.nomeSocial,
          dataNascimento: data.dataNascimento,
          email: data.email,
          racaOuCor: data.racaOuCor,
          nomeDaMae: data.nomeDaMae,
          logradouroCep: data.logradouroCep?.replaceAll('-', ''),
          ufAbreviado: data.UFabreviado,
          logradouroCidade: data.logradouroCidade,
          logradouroBairro: data.logradouroBairro,
          logradouro: data.logradouro,
          logradouroNumero: data.logradouroNumero,
          logradouroComplemento: data.logradouroComplemento,
        };

        await IndividuoService.save(id, {
          ...newUser,
          imagem: !userScreen?.imagem ? '' : userScreen?.imagem,
        });
        setCanEdit(false);
        transformIndividuoToFormData(newUser);
        hideUserData(newUser);
        toast.success('Alteração realizada com sucesso');
      } catch (error) {
        toast.error('Tivemos um problema.');
      } finally {
        setLoading(false);
      }
    },
    [id, newProfile],
  );

  const getUserData = useCallback(async () => {
    if (!id) {
      return;
    }
    const user = await IndividuoService.getById(id);
    setUserScreen(user);
    transformIndividuoToFormData(user);
    hideUserData(user);
  }, [transformIndividuoToFormData, hideUserData, id]);

  useEffect(() => {
    updateUFList().then(() => {
      getUserData();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!cep) return;
    if (cep.length === 9) {
      handleCEPChange(cep);
      //setDisable(true);
    } else {
      //setDisable(false);
    }
  }, [cep]);

  const handleCEPChange = useCallback(
    async (value: string) => {
      if (value.length === 9) {
        const info = await getCEP(value);
        setNewProfile({ cidadeId: info.ibge });

        setValue('UFabreviado', info.uf);
        setValue('logradouroCidade', info.localidade);
        setValue('logradouroBairro', info.bairro);
        setValue('logradouro', info.logradouro);
      }
    },
    [getCEP, updateCityList],
  );

  return (
    <Container>
      {/* <CardGlobal maxWidthDefault>
        <CardHeader>
          <h1>Perfil</h1>

          {!canEdit && (
            <button type="button" onClick={handleEdit}>
              <FiEdit2 /> Editar dados
            </button>
          )}
        </CardHeader>

        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(handleRegister)();
          }}
        >
          <Content>
            <Item gridArea="cpf">
              <TextInput
                title="CPF"
                name="cpf"
                control={control}
                labelInLine={false}
                mask={canEdit ? '999.999.999-99' : 'none'}
                error={errors.cpf?.message}
                disabled={dontEdit}
                type="int"
              />
            </Item>

            <Item gridArea="nome">
              <TextInput
                title="Nome completo"
                name="nomeCompleto"
                control={control}
                labelInLine={false}
                error={errors.nomeCompleto?.message}
                disabled={dontEdit}
                type="text"
              />
            </Item>

            <Item gridArea="cns">
              <TextInput
                title="CNS"
                name="cns"
                control={control}
                labelInLine={false}
                mask="999 9999 9999 9999"
                type="int"
                disabled={!canEdit}
                error={errors.cns?.message}
              />
            </Item>

            <Item gridArea="nomeSocial">
              <TextInput
                title="Nome social"
                name="nomeSocial"
                control={control}
                labelInLine={false}
                mask="none"
                error={errors.nomeSocial?.message}
                disabled={!canEdit}
                type="text"
              />
            </Item>

            <Item gridArea="dataNascimento">
              <TextInput
                title="Data de nascimento"
                name="dataNascimento"
                control={control}
                labelInLine={false}
                mask="none"
                type="date"
                error={errors.dataNascimento?.message}
                disabled={dontEdit}
              />
            </Item>

            <Item gridArea="email">
              <TextInput
                title="Email"
                name="email"
                control={control}
                labelInLine={false}
                mask="none"
                type="email"
                disabled={!canEdit}
                error={errors.email?.message}
              />
            </Item>

            <Item gridArea="telefoneCelular">
              <TextInput
                title="Número de telefone"
                name="telefoneCelular"
                control={control}
                labelInLine={false}
                mask="(99) 9999-9999"
                error={errors.telefoneCelular?.message}
                disabled={!canEdit}
                type="int"
              />
            </Item>

            <Item gridArea="sexo">
              <InputSelect
                name="sexo"
                control={control}
                options={genreList}
                title="Sexo"
                disabled={dontEdit}
                error={errors.sexo?.message}
              />
            </Item>

            <Item gridArea="racaOuCor">
              <InputSelect
                name="racaOuCor"
                control={control}
                options={breedList}
                title="Raça ou cor"
                disabled={dontEdit}
                error={errors.racaOuCor?.message}
              />
            </Item>

            <Item gridArea="nomeDaMae">
              <TextInput
                title="Nome da mãe"
                name="nomeDaMae"
                control={control}
                labelInLine={false}
                mask="none"
                disabled={dontEdit}
                error={errors.nomeDaMae?.message}
                type="text"
              />
            </Item>

            <Item gridArea="logradouroCep">
              <TextInput
                title="CEP"
                name="logradouroCep"
                control={control}
                labelInLine={false}
                mask="99999-999"
                type="int"
                disabled={!canEdit}
                error={errors.logradouroCep?.message}
              />
            </Item>

            <Item gridArea="UFabreviado">
              <TextInput
                name="UFabreviado"
                control={control}
                title="UF"
                disabled={dontEdit}
                error={errors.UFabreviado?.message}
                type="text"
              />
            </Item>

            <Item gridArea="logradouroCidade">
              <TextInput
                name="logradouroCidade"
                control={control}
                title="Cidade"
                disabled={dontEdit}
                type="text"
              />
            </Item>

            <Item gridArea="logradouroBairro">
              <TextInput
                name="logradouroBairro"
                control={control}
                title="Bairro"
                disabled={dontEdit}
                error={errors.logradouroBairro?.message}
                type="text"
              />
            </Item>

            <Item gridArea="logradouro">
              <TextInput
                name="logradouro"
                control={control}
                title="Logradouro"
                disabled={dontEdit}
                error={errors.logradouro?.message}
                type="text"
              />
            </Item>

            <Item gridArea="logradouroNumero">
              <TextInput
                name="logradouroNumero"
                control={control}
                title="Número"
                disabled={!canEdit}
                type="int"
              />
            </Item>

            <Item gridArea="logradouroComplemento">
              <TextInput
                name="logradouroComplemento"
                control={control}
                title="Complemento"
                error={errors.logradouroComplemento?.message}
                disabled={!canEdit}
                type="text"
              />
            </Item>
          </Content>

          {canEdit && <Button loading={loading}>Salvar</Button>}
        </form>
      </CardGlobal> */}
    </Container>
  );
};

export default Perfil;
