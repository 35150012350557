/* eslint-disable */
/* tslint-disable */
import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { MdClose } from 'react-icons/md';

import InputChatForm from '../../../../components/InputChatForm';
import { MessageSectionProps, UsersType } from '..';

import { Container, MessageArea, MessageSection, Header } from './styles';

interface ChatProps {
  messages: MessageSectionProps[];
  sendMessage(message: string): void;
  users?: UsersType;
  closeChat(): void;
}

const Chat: React.FC<ChatProps> = ({
  messages,
  sendMessage,
  users,
  closeChat,
}) => {
  const messageAreaRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    messageAreaRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [messages]);

  return (
    <Container>
      <Header>
        <h1>Mensagens</h1>

        <button type="button" onClick={() => closeChat()}>
          <MdClose size={20} />
        </button>
      </Header>

      {messages.length ? (
        <MessageArea>
          {messages.map(section => (
            <MessageSection key={section.id}>
              <div>
                <strong>
                  {(users && users[section.userId]) || 'Desconhecido'}
                </strong>

                <span>{format(section.hour, 'HH:mm')}</span>
              </div>

              {section.messages.map(({ id, message }) => (
                <p key={id}>{message}</p>
              ))}
            </MessageSection>
          ))}
          <p ref={messageAreaRef} />
        </MessageArea>
      ) : (
        <p>Não há mensagens nesta chamada.</p>
      )}
      <InputChatForm onSend={sendMessage} />
    </Container>
  );
};

export default Chat;
