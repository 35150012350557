import { useState } from 'react';
import EstabelecimentosService from '../../services/EstabelecimentosService';
import { Estabelecimento } from '../../models/estabelecimento';
import { AxiosError } from 'axios';
import { IPicker } from '../../models/IPicker';

const useStablishment = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [stablishmentPicker, setStablishmentPicker] = useState<IPicker[]>([]);

  const getStablishment = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await EstabelecimentosService.getAll();
      setStablishmentPicker(
        response
          .filter(item => item.ativo == true)
          .map(item => ({
            key: item.id,
            label: item.nomeFantasia,
          })),
      );
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setError((e as AxiosError).message);
    } finally {
      setLoading(false);
    }
  };

  return {
    getStablishment,
    stablishmentPicker,
    loading,
    success,
    error,
  };
};

export default useStablishment;
