import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { RiStethoscopeLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import moment from 'moment';
import Button from '../../../../components/Button';
import CardGlobal from '../../../../components/CardGlobal';
import CleanView from '../../../../components/CleanView';
import Loading from '../../../../components/Loading';
import { useAuth } from '../../../../hooks/auth';
import { Notificacao } from '../../../../models/notificacao';
import AgendamentoService from '../../../../services/AgendamentoService';
import { colors } from '../../../../styles/theme/colors';

import {
  CardMedicalAppointment,
  Container,
  Content,
  ContainerProps as NextAppointmentsProps,
} from './styles';
import { useSchedules } from '../../../../hooks/local/useSchedules';

interface MedicalAppointmentProps {
  id?: string;
  type?: string;
  date?: string;
  hora?: string;
  doctor?: string;
  specialty?: string;
}

const NextAppointments: React.FC<NextAppointmentsProps> = (...rest) => {
  const { id } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<MedicalAppointmentProps[]>(
    [],
  );

  const {
    error,
    getSchedules,
    getSchedulesById,
    schedule,
    loading: loadingChedules,
    scheduleItems,
    success,
  } = useSchedules();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    console.log(error);
  }, [error]);

  useEffect(() => {
    getSchedules(false);
  }, [getSchedules]);

  useEffect(() => {
    const scheduleId = localStorage.getItem('scheduleId');
    if (!scheduleId) {
      toast.error('Atendimento não localizado.');
      return;
    }
    //getSchedulesById(scheduleId);
    history.push(`/area/agendamento/detalhes?id=${scheduleId}`);
  }, []);

  return (
    <Container {...rest}>
      <CardGlobal>
        <h1>Próximas Consultas</h1>
        <Content isLoading={loadingChedules}>
          {!loadingChedules ? (
            scheduleItems && scheduleItems.length > 0 ? (
              <>
                {scheduleItems?.map(appointment => (
                  <CardMedicalAppointment
                    key={appointment.id}
                    onClick={() =>
                      history.push(
                        `/area/agendamento/detalhes?id=${appointment.id}`,
                      )
                    }
                  >
                    <div>
                      <div>
                        <RiStethoscopeLine
                          size={25}
                          color={colors.primaryDark}
                        />
                      </div>
                      <strong>{appointment.tipo}</strong>
                    </div>
                    <div>
                      <div>
                        <span>Data:</span>
                        <span>Hora:</span>
                        <span>Médico:</span>
                        <span>Especialidade:</span>
                      </div>
                      <div>
                        <strong>{appointment.data ?? ''}</strong>
                        <strong>{appointment.hora ?? ''}</strong>
                        <strong>{appointment.profissional ?? ''}</strong>
                        <strong>{appointment.especialidade ?? ''}</strong>
                      </div>
                    </div>
                  </CardMedicalAppointment>
                ))}
                {showButton && (
                  <Button
                    btnType="outlinePrimary"
                    onClick={() => history.push('/area/agendamento')}
                  >
                    Todas as consultas
                  </Button>
                )}
              </>
            ) : (
              <CleanView text="Nenhuma consulta agendada" />
            )
          ) : (
            <Loading size={60} spinnerColor="gray" />
          )}
        </Content>
      </CardGlobal>
    </Container>
  );
};

export default NextAppointments;
