import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';

import AppProvider from './hooks';

import GlobalStyle from './styles/global';
import theme from './styles/theme';

import 'react-toastify/dist/ReactToastify.min.css';

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'Meeds';
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Routes />
        </AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
        />

        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
};

export default App;
