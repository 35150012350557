export const EMAIL_INVALID = 'Digite um email válido.';
export const REQUIRED = 'Campo obrigatório';
export const REQUIRED_FILE = 'Arquivo obrigatório';
export const INVALID_CPF = 'CPF inválido';
export const INVALID_CNS = 'CNS inválido';
export const INVALID_NAME = 'Nome inválido';
export const INVALID_EMAIL = 'Email inválido';
export const INVALID_VALUE = 'Valor inválido';
export const INVALID_DATE = 'Data inválida';
export const MAX_LENGTH_CEL =
  'O número de celular deve ter no máximo 16 dígitos';
export const MIN_LENGTH_CEL =
  'O número de celular deve ter no mínimo 11 dígitos';
export const MIN_LENGTH_PASSWORD = 'A senha deve ter no mínimo 6 dígitos';
export const SAME_PASSWORD = 'As senhas devem ser iguais.';
