import styled, { keyframes } from 'styled-components';

const appearFromUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;
  padding: 15px 15px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const Logo = styled.img`
  margin-bottom: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 350px;

  img {
    width: 350px;
    animation: ${appearFromUp} 2s;
  }

  .novetech {
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-left: -4%;
  }

  .nove {
    width: 60px;
    padding-top: center 24px;
    max-width: 65rem;
    display: flex;
  }

  .nov {
    height: 60px;
    width: 240px;
    margin: -2%;
    margin-left: 2%;
  }
  .loginendereco {
    margin: 0;
    font-size: 85%;
    line-height: 60%;
    padding-top: 7px;
    font-style: normal;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
  }

  form {
    width: 100%;
    animation: ${appearFromDown} 2s;

    > div + div {
      margin-top: 15px;
    }

    > div {
      strong {
        color: ${({ theme }) => theme.colors.graySecondaryDark};
      }
    }

    > button {
      width: 100%;
      margin-top: 20px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    animation: ${appearFromDown} 2s;
    width: 100%;
    margin-top: 20px;

    a {
      text-decoration: none;
      font-weight: bold;
      color: #fff;
    }

    @media (max-width: 320px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      a + a {
        margin-top: 10px;
      }
    }
  }
`;
