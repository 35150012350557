import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content:center;
  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    padding: 0 10px;
    grid-template-columns: 1fr;
  }

  > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;

    > h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
    }
  }
`;

export const DocumentsAndInfosContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    margin: 0;
  }

  > div {
    flex: 0.5;
    padding: 30px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      padding: 0;
    }

    &:last-child {
      > div {
        overflow-y: auto;
        min-height: 100%;
        max-height: 368px;

        @media screen and (max-width: ${({ theme }) =>theme.gridBreakpoints.sm}) {
          min-height: initial;
          max-height: initial;
        }

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: ${({ theme }) => theme.colors.grayPrimaryLight};
        }
      }
    }

    & > div {
      margin: 0;

      @media screen and (max-width: ${({ theme }) =>theme.gridBreakpoints.sm}) {
        margin: 10px 0;
      }
    }
  }
`;
