import { api } from './Api';
import { Procedimento } from '../models/procedimento';
import { ApiObject } from '../models/apiObject';
import IGetReturn from '../models/IGetReturn';
import { Estabelecimento } from '../models/estabelecimento';
import { AxiosResponse } from 'axios';

const getByType = async (type: string): Promise<Procedimento[]> => {
  const { data } = await api.get<ApiObject<Procedimento>>(
    `Procedimento?Tipo=${type}&sort=Descricao`,
  );

  if (!data) {
    return [];
  }

  return data.items;
};

async function getProcedures(
  estabelecimentoId: string,
  skip = 1,
  take = 10,
): Promise<Estabelecimento> {
  const response = await api.get<unknown, AxiosResponse<Estabelecimento>>(
    `/estabelecimentos/${estabelecimentoId}/procedimentos`,
    {
      params: { skip, take },
    },
  );
  return response.data;
}

export default { getByType, getProcedures };
