import { useState } from 'react';
import { IPicker } from '../../models/IPicker';
import ProcedimentoEspecialidadeService from '../../services/ProcedimentoEspecialidadeService';

const useSpecialty = () => {
  const [specialtyPicker, setSpecialtyPicker] = useState<IPicker[]>([]);

  const getSpecialty = async (stablishmentId: string) => {
    const response = await ProcedimentoEspecialidadeService.getProcedures(
      stablishmentId,
    );

    const pick = response.procedimentos?.map(item => ({
      key: item.procedimento.id as string,
      label: item.procedimento.descricao as string,
    }));

    setSpecialtyPicker(pick as IPicker[]);
  };

  return {
    getSpecialty,
    specialtyPicker,
  };
};

export default useSpecialty;
