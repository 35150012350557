import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

interface SelectProps {
  isErrored: boolean;
  isFocused: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  strong {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const Select = styled(ReactSelect)<SelectProps>`
  background: #ffffff;
  border-radius: 6px;
  min-width: 75px;
  padding: 1px 0;
  margin-top: 5px;

  input {
    border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
  }

  input:focus {
    border-color: ${props => props.theme.colors.primary} !important;
  }

  > div:first-child {
    border: 1px solid ${props => props.theme.colors.gray2};
  }

  div {
    box-shadow: 0 0 0 !important;
    border-width: 2px !important;
    border-color: ${({ theme }) => theme.colors.graySecondaryLight} !important;
    min-height: 32px !important;

    div > input {
      color: #000 !important;
    }

    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.primary} !important;
    }
  }

  ${props =>
    props.isFocused &&
    css`
      div {
        border-color: ${props.theme.colors.primary} !important;
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      > div {
        border-color: ${props.theme.colors.danger} !important;
      }
    `}

  .react-select__indicator-separator {
    margin-right: 0 !important;
  }

  .react-select__indicator {
    padding: 0;
  }

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__indicator,
  .react-select__input {
    display: flex !important;
    align-items: center !important;
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  .react-select__control--is-disabled {
    background-color: #ffffff;
  }

  /* .react-select__control--is-focused {
    border: 2px solid ${props => props.theme.colors.primary};
  } */
`;

export const Title = styled.strong`
  position: absolute;
  top: -15px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;
